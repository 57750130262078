/**
 * Script: store/evaluationStore.ts
 * This file stores and manages states related to an assignment's evaluation.
 *
 * Dependencies are
 * createStore, StateCreatorDev    - store/utils      - function for creating a store
 * Evaluation                      - types            - interfaces
 */

import { createStore, StateCreatorDev } from "store/utils";
import { Evaluation } from "types";

type EvaluationState = {
  content: string;
  criteriasToAnalyze: string[];
  evaluation: {
    [category: string]: Evaluation | null
  };
  initialAnalyze: boolean;
  prevContent: string;
  selectedCategory: string;
  tools: string[];
  wordReplacement: WordReplacement;
};

type EvaluationStateStore = {
  clearEvaluation: () => void;
  clearEvaluationState: () => void;
  setContent: (content: string) => void;
  setCriteriasToAnalyze: (criteriasToAnalyze: string[]) => void;
  setEvaluation: (evaluation: Evaluation, category:string) => void;
  setInitialAnalyze: (initialized: boolean) => void;
  setPrevContent: (prevContent: string) => void;
  setSelectedCategory: (category: string) => void;
  setTools: (tools: string[]) => void;
  replaceWord: (wordReplacement: WordReplacement) => void;
  removeAnnotation: (annotationId: string, category: string, type: string) => void;
} & EvaluationState;

export interface WordReplacement {
  wordToBeReplacedId: string;
  wordToBeReplaced: string;
  wordToInsert: string;
  category: string;
  type: string;
}

const initialState: EvaluationState = {
  content: "",
  criteriasToAnalyze: [],
  evaluation: {
    communication: null,
    content: null,
    spelling: null,
    language: null,
    structure: null,
    punctuation: null,
  },
  initialAnalyze: false,
  prevContent: null,
  selectedCategory: null,
  tools: [], // Should be refactored to isAutocorrectOn: boolean
  wordReplacement: null,
};

/**
 * A method for setting the store's initial state, and defining all functions allowed in this store.
 */
const store: StateCreatorDev<EvaluationStateStore> = (set, get) => ({
  ...initialState,

  /**
   * A function for clearing the evaluation.
   */
  clearEvaluation: () => set({ ...get(), evaluation: { ...initialState.evaluation } }, false, "clearEvaluation"),

  /**
   * A function for resetting the store and still preserve the edited content.
   */
  clearEvaluationState: () => {
    const content = get().content;
    set({ ...get(), ...initialState, content }, false, "clearEvaluationState")
  },

  /**
   * A function for setting the criterias that Skrible should analyze.
   * @param {string[]} criteriasToAnalyze An array of the criteria IDs that should be analyzed.
   */
  setCriteriasToAnalyze: criteriasToAnalyze => set({...get(), criteriasToAnalyze}, false, "setCriteriasToAnalyze"),

  /**
   * A function for setting the store's content
   * @param {string} content A string representation of the text content that should be ananlyzed.
   */
  setContent: content => set({ ...get(), content }, false, "setContent"),

  /**
   * A function for setting the evaluation for a category
   * @param {Evaluation} evaluation The evaluation.
   * @param {string} category The category ID.
   */
  setEvaluation: (evaluation, category) => set({ ...get(), evaluation: {
    ...get().evaluation,
    [category]: evaluation}
  }, false, "setEvaluation"),

  /**
   * A function for removing an annotation after the annocation has been programmatically corrected.
   * @param {string} annotationId The annotation ID.
   * @param {string} category The category ID of the corrected evaluation.
   * @param {string} type The annotation type.
   */
  removeAnnotation: (annotationId, category, type) => {
    const { evaluation } = get();
    const evaluationObject = { ...evaluation[category] };

    const deleteIndex = evaluationObject.annotations[type].findIndex(object => object.id === annotationId);
    evaluationObject.annotations[type].splice(deleteIndex, 1);
    evaluationObject.counts[type] = +evaluationObject.counts[type] - 1;
    evaluation[category] = evaluationObject;

    set({ ...get(), evaluation }, false, "removeAnnotation");
  },

  /**
   * A function for setting the previous content.
   * @param {string} prevContent A string representation of the content.
   */
  setPrevContent: prevContent => set({ ...get(), prevContent }, false, "setPrevContent"),

  /**
   * A function setting the a value telling whether the evaluation is already run or not. If initialAnalyze is true, we can avoid multiple analyzes when the content changes.
   * @param {boolean} initialized
   */
  setInitialAnalyze: initialized => set({ ...get(), initialAnalyze: initialized}, false, "setInitialAnalyze"),

  /**
   * A function for setting the selected category.
   * @param {string} selectedCategory The ID of the selected category.
   */
  setSelectedCategory: selectedCategory => set({ ...get(), selectedCategory}, false, "setSelectedCategory"),

  /**
   * A function for setting tools.
   * @param {string[]} tools An array of tool IDs.
   */
  setTools: tools => set({ ...get(), tools }, false, "setTools"),

  /**
   * A function for programmatically replacing a mispelled word.
   * @param {WordReplacement} wordReplacement The word that should be replaced.
   */
  replaceWord: wordReplacement => set({ ...get(), wordReplacement }, false, "replaceWord"),

});

export default createStore(store, "evaluation");
