/**
 * Script: store/reviewStore.ts
 * This file stores and manages states related to an assignment's review.
 *
 * Dependencies are
 * services                         - helpers/services    - backend-services
 * StateCreatorDev, createStore     - store/utils         - functions for creating and restoring a store
 * ReviewState, ReviewStateStore    - types               - interfaces
 */

import services from "helpers/services";
import { ReviewState, ReviewStateStore } from "types";

import { StateCreatorDev, createStore } from "./utils";

const initialState: ReviewState = {
  error: null,
  comments: [],
  isEditingReview: false,
  review: null
};

/**
 * Review Store
 */
const store: StateCreatorDev<ReviewStateStore> = (set, get) => ({
  ...initialState,

  /**
   * A function for updating the comments array after adding a comment.
   * @param {string} commentId The ID of the comment.
   */
  addComment: (commentId: string) => {
    const comments = [...get().comments].concat({ id: commentId });

    set({ ...get(), comments }, false, "addComment");
  },

  /**
   * A function for updating the comments array after a comment has changed.
   * @param {string} commentId The comment ID.
   * @param {Comment} comment The updated comment.
   */
  changeComment: (commentId, comment) => {
    const comments = [...get().comments].filter(c => c.id !== commentId).concat(comment);

    set({ ...get(), comments }, false, "changeComment");
  },

  /**
   * A function for fetching all comments belonging to a text.
   * @param {string} textId The text ID.
   */
  fetchComments: async textId => {
    try {
      const { list: comments } = await services.comments.getAll(textId);

      set({ ...get(), comments, error: null }, false, "fetchComments");
    } catch (error) {
      set({ ...get(), error, comments: [] }, false, "fetchComments - error");
    }
  },

  /**
   * A function for updating the comments array after a comment has been removed.
   * @param {string} commentId The ID of the removed comment.
   */
  removeComment: commentId => {
    const comments = [...get().comments].filter(c => c.id !== commentId);

    set({ ...get(), comments }, false, "removeComment");
  },

  /**
   * A function for toggling edit mode for a comment.
   * @param {string} commentId The ID of the toggled comment.
   */
  toggleEditingComment: commentId => {
    const comments = [...get().comments];
    const comment = comments.find(c => c.id === commentId);
    comment.isEditing = !comment.isEditing;

    set({ ...get(), comments }, false, "toggleEditingComment");
  },

  /**
   * A function for setting the review of a text.
   * @param {Review} review The review that should be set.
   */
  addReview: review => set({ ...get(), review }, false, "addReview"),

  /**
   * A function for fetching the review of a text.
   * @param {string} textId The text's ID.
   */
  fetchReviews: async textId => {
    try {
      const { list } = await services.reviews.getAll(textId);

      // We only support one review for now, hence first item from the list
      set({ ...get(), review: list[0], error: null }, false, "fetchReviews");
    } catch (error) {
      set({ ...get(), error, review: null }, false, "fetchReviews - error");
    }
  },

  /**
   * A function for removing a review.
   */
  removeReview: () => set({ ...get(), review: initialState.review }, false, "removeReview"),

  /**
   * A function for toggling edit mode for a review.
   */
  toggleEditingReview: () => set({ ...get(), isEditingReview: !get().isEditingReview }, false, "toggleEditingReview")
});

export default createStore(store, "Review");
