/**
 * Script: store/globalStore.ts
 * This file stores and manages states related to learning goals.
 *
 * Dependencies are
 * services                                 - helpers/services    - backend-services
 * StateCreatorDev, createStore             - store/utils         - functions for creating and restoring a store
 * Discipline, GrepGoalState, GrepGoalStateStore,
   GrepLearningGoalShort                    - types               - interfaces
 */

import { URLSearchParams } from "url";

import services from "helpers/services";
import { createStore, StateCreatorDev } from "store/utils";
import { Discipline, GrepGoalState, GrepGoalStateStore, GrepLearningGoalShort } from "types";

export const initialState: GrepGoalState = {
  disciplines: [],
  error: null,
  isLoading: false,
  learningGoals: [],
  queriedGoals: [],
  selectedDiscipline: null,
  selectedGoals: [],
  selectedGoalsDetails: [],
  selectedGrades: [],
};

/**
 * Grep goal store
 */
const store: StateCreatorDev<GrepGoalStateStore> = (set, get) => {
  return {
    ...initialState,

    /**
     * A function for setting the selected grades.
     * @param {string[]} grades An array of the selected grades.
     */
    addSelectedGrades: (grades: string[]) => {
      const selectedGrades = [...get().selectedGrades.concat(grades)];
      set({ ...get(), selectedGrades }, false, "addSelectedGrades")
    },

    /**
     * A function for clearing the disciplines and learning goals.
     */
    clearFilter: () => set({ ...get(), disciplines: [], learningGoals: [] }, false, "clearFilter"),

    /**
     * A function for clearing the learning goals.
     */
    clearLearningGoals: () => set({ ...get(), learningGoals: []}, false, "clearLearningGoals"),

    /**
     * A function for fetching disciplines.
     * @param {URLSearchParams} params Search query parameters.
     */
    fetchDisciplines: async (params: URLSearchParams) => {
      try {
        const disciplines = await services.grepLearningGoals.getDisciplinesByGrade(params);

        set({ ...get(), disciplines, error: null }, false, "fetchDisciplines");
      } catch (error) {
        set({ ...get(), disciplines: [], error }, false, "fetchDisciplines - error");
      }
    },

    /**
     * A function for fetching learning goal details.
     * @param {string[]} goals An array of the learning goal IDs we want to fetch the details for.
     */
    fetchGoalDetails: async (goals: string[]) => {
      const selectedGoalsDetails: GrepLearningGoalShort[] = [];

      for(const learningGoalId of goals) {
        try {
          const details = await services.grepLearningGoals.getLearningGoalById(learningGoalId);

          if(details) {
            selectedGoalsDetails.push(details);
          }

          set({ ...get(), selectedGoalsDetails, error: null }, false, "fetchGoalDetails");

        } catch (error) {
          set({ ...get(), selectedGoalsDetails: [], error }, false, "fetchGoalDetails - error");
        }
      }
    },

    /**
     * A function for fetching learning goals.
     * @param {URLSearchParams} params Search parameters for grades and disciplines.
     * @param {string} query The search query we want the learning goals to contain.
     */
    fetchLearningGoals: async (params: URLSearchParams, query?: string) => {
      set({ ...get(), isLoading: true }, false, "setLoading - false");
      try {
        const goals = await services.grepLearningGoals.getLearningGoals(params);

        const selectedGoals = get().selectedGoals;

        const learningGoals = goals.filter((goal: GrepLearningGoalShort)  => !selectedGoals.includes(goal.id));

        // if query exist at time of fetch, also update queriedGoals
        if(query.length > 0) {
          get().queryGoals(query);
        }

        set({ ...get(), error: null, learningGoals: [ ...get().selectedGoalsDetails, ...learningGoals ] }, false, "fetchLearningGoals");
      } catch (error) {
        set({ ...get(), error, learningGoals: [] }, false, "fetchLearningGoals - error");
      } finally {
        set({ ...get(), isLoading: false }, false, "setLoading - false");
      }
    },

    /**
     * A function for filtering the learning goals that contain the search query.
     * @param {string} searchQuery The search query.
     */
    queryGoals: (searchQuery: string) => {
      if(searchQuery.length > 0) {
        const selectedGoals = get().selectedGoals;

        const queriedGoals = [ ...get().learningGoals ]
          .filter(goal =>  goal.contentDef.includes(searchQuery) && !selectedGoals.includes(goal.id));

        set({ ...get(), queriedGoals: [ ...get().selectedGoalsDetails, ...queriedGoals ] }, false, "setQueriedGoals");
      } else {
        set({ ...get(), queriedGoals: [ ...get().learningGoals] }, false, "setQueriedGoals");
      }
    },

    /**
     * A function for removing a selected grade from the store.
     * @param {string} grade The grade that should be removed.
     */
    removeSelectedGrade: (grade: string) => {
      const selectedGrades = [...get().selectedGrades.filter(item => item !== grade)];
      set({ ...get(), selectedGrades }, false, "removeSelectedGrades")
    },

    /**
     * A function to reset the grep goal store.
     */
    resetState: () => set({...get(), ...initialState}, false, "resetState"),

    /**
     * A function to set the selected discipline.
     * @param {Discipline} selectedDiscipline The selected discipline.
     */
    selectDiscipline: (selectedDiscipline: Discipline) => set({ ...get(), selectedDiscipline }, false, "selectDiscipline"),

    /**
     * A function to set all disciplines.
     * @param {Discipline[]} disciplines An array of disciplines.
     */
    setDisciplines: (disciplines: Discipline[]) => set({ ...get(), disciplines }, false, "setDisciplines"),

    /**
     * A function to set selected goals.
     * @param {string[]} selectedGoals An array og the selected goals.
     */
    setSelectedGoals: (selectedGoals: string[]) => set({ ...get(), selectedGoals }, false, "setSelectedGoals"),
  };
};

export default createStore(store, "GrepGoal");
