/**
 * Script: locale/nb.ts
 * Translation file for Norwegian Bokmål.
 */
export default {
  "a11y_assignments_tabs": "Forskjellige typer skriveoppdrag",
  "a11y_label_assignment_menu_button": "Oppdragsmeny",
  "a11y_label_button_back": "Tilbake",
  "a11y_label_button_close": "Lukk",
  "a11y_label_button_more": "Åpne",
  "a11y_label_comment_menu_button": "Kommentarmeny",
  "a11y_label_editor": "Redigeringsverktøy",
  "a11y_label_feedback_button": "Gi tilbakemelding",
  "a11y_label_filter_learning_goals": "Søk etter kompetansemål",
  "a11y_label_ignore_button": "Ignorer",
  "a11y_label_print_menu_button": "Skriv ut-meny",
  "a11y_label_scroll_to_text_content": "Gå til avsnittet 'Hva hjelper Skrible med?'",
  "a11y_label_search": "Søk",
  "a11y_label_sort_alphabetically": "Sorter alfabetisk",
  "a11y_label_toolbar_link_input": "Lim inn lenken",
  "a11y_label_user_menu_toggle": "Åpne",
  "a11y_label_word_count_max": "Maksimum antall ord",
  "a11y_label_word_count_min": "Minimum antall ord",
  "a11y_skip_to_assignment_editor": "Gå til redigeringsverktøyet",
  "a11y_skip_to_assignment_evaluation_criteria": "Gå til vurderingskriteriene",
  "a11y_skip_to_assignment_settings": "Gå til innstillinger",
  "a11y_skip_to_assignment_tabs": "Gå til fanene med forskjellige typer skriveoppdrag",
  "a11y_skip_to_assignment_tools": "Gå til verktøyene",
  "a11y_skip_to_create_assignment": "Gå til Nytt skriveoppdrag-knappen",
  "a11y_skip_to_main_content": "Gå til hoveddelen av sida",
  "a11y_skip_to_start_writing": "Gå til Begynn å skrive-knappen",
  "a11y_skip_to_student_text_review": "Se lærerens vurdering av teksten",
  "a11y_skip_to_teacher_info": "Gå til lærerinformasjonen",
  "a11y_skip_to_teacher_text_review": "Vurder teksten",
  "a11y_skip_to_text_evaluation": "Gå til vurdering av teksten",
  "a11y_skip_to_text_tabs": "Gå til beskrivelsen av skriveoppdraget",
  "a11y_skip_to_writing_frame_filter": "Gå til søkefeltet til skriverammene",
  "a11y_student_assignment_tabs": "Teksten din",
  "a11y_teacher_assignment_tabs": "Innleveringer",
  "assignment_description_placeholder": "Beskriv skriveoppdraget til eleven her ...",
  "assignment_due_date": "Fristen er {{date}} kl. {{time}}.",
  "assignment_due_days": {
    "one": "Fristen er om {{count}} dag.",
    "other": "Fristen er om {{count}} dager."
  },
  "assignment_due_hours": "Fristen er om 4 timer",
  "assignment_due_today": "Fristen er i dag.",
  "assignment_due_weekday": "Fristen er på fredag.",
  "assignment_no_class": "Du har ikke valgt noen klasse",
  "assignment_no_deadline": "Du har ikke valgt noen frist",
  "assignment_no_evaluation_criteria": "Du har ikke valgt noe vurderingskriterium",
  "assignment_no_learning_goals": "Du har ikke valgt noe kompetansemål",
  "assignment_no_tools": "Du har ikke valgt noe verktøy",
  "assignment_title_placeholder": "Gi skriveoppdraget en overskrift",
  "assignments_not_available": "Du har ingen skriveoppdrag",
  "autosave_text_error_1": "Skrible mistet kontakten. Du har ulagrede endringer.",
  "autosave_text_error_2": "Alt blir lagret når Skrible får kontakt igjen.",
  "autosave_text_saving": "Lagrer...",
  "autosave_text_success": "Alle endringene er lagret",
  "completed_texts_not_available": "Du har ingen ferdige skriveoppdrag",
  "criteria_capital_letter_student_description": "Du skal bruke stor og liten forbokstav riktig.",
  "criteria_capital_letter_subtitle": "Bruk stor forbokstav",
  "criteria_capital_letter_teacher_description": "Eleven skal bruke stor og liten forbokstav riktig.",
  "criteria_capital_letter_text": "Sjekk om dette ordet skal ha stor forbokstav:",
  "criteria_capital_letter_title": "Stor og liten forbokstav",
  "criteria_comma_student_description": "Du skal følge kommaregler og skrive komma foran «men».",
  "criteria_comma_subtitle": "Sett komma foran men",
  "criteria_comma_teacher_description": "Eleven skal følge kommaregler og skrive komma foran «men».",
  "criteria_comma_text": "Det ser ut som du mangler et komma her:",
  "criteria_comma_title": "Komma",
  "criteria_exclamation_mark_ratio_subtitle": "Fjern noen utropstegn",
  "criteria_exclamation_mark_ratio_text": "Det ser ut som du har for mange utropstegn i teksten.",
  "criteria_exclamation_mark_student_description": "Du skal bruke utropstegn når det passer, men ikke overdrive antall utropstegn.",
  "criteria_exclamation_mark_subtitle": "Fjern ekstra utropstegn",
  "criteria_exclamation_mark_teacher_description": "Eleven skal bruke utropstegn når det passer, men ikke overdrive antall utropstegn.",
  "criteria_exclamation_mark_text": "Det ser ut som du har for mange utropstegn her:",
  "criteria_exclamation_mark_title": "Utropstegn",
  "criteria_full_stop_student_description": "Du skal skrive titler uten punktum.",
  "criteria_full_stop_subtitle": "Fjern punktum i overskriften",
  "criteria_full_stop_teacher_description": "Eleven skal skrive titler uten punktum.",
  "criteria_full_stop_text": "Overskrifter skal ikke ha punktum:",
  "criteria_full_stop_title": "Punktum",
  "criteria_long_sentence_student_description": "Du skal skrive setninger som ikke er for lange.",
  "criteria_long_sentence_subtitle": "Kan du korte ned setningen, eller lage to setninger?",
  "criteria_long_sentence_teacher_description": "Eleven skal skrive setninger som ikke er for lange.",
  "criteria_long_sentence_text": "Denne setningen er lang. Den kan være vanskelig å lese.",
  "criteria_long_sentence_title": "Setningslengde",
  "criteria_paragraph_student_description": "Du skal ha avsnitt i teksten din.",
  "criteria_paragraph_subtitle": "Kan du korte ned avsnittet, eller dele det i to avsnitt?",
  "criteria_paragraph_teacher_description": "Eleven skal ha avsnitt i teksten sin.",
  "criteria_paragraph_text": "Dette avsnittet er langt. Det kan være vanskelig å lese.",
  "criteria_paragraph_text_subtitle": "Kan du dele teksten i avsnitt?",
  "criteria_paragraph_text_text": "Teksten din mangler avsnitt. Finner du steder hvor du kan dele opp teksten din?",
  "criteria_paragraph_title": "Avsnitt",
  "criteria_question_mark_student_description": "Du skal bruke spørsmålstegn når du skriver et spørsmål.",
  "criteria_question_mark_subtitle": "Legg til spørsmålstegn",
  "criteria_question_mark_teacher_description": "Eleven skal bruke spørsmålstegn i spørsmål.",
  "criteria_question_mark_text": "Denne setningen ser ut som et spørsmål, men mangler spørsmålstegn.",
  "criteria_question_mark_title": "Spørsmålstegn",
  "criteria_repetition_student_description": "Du skal variere språket ditt.",
  "criteria_repetition_subtitle": "Kan du bytte ut dette ordet noen steder i teksten?",
  "criteria_repetition_teacher_description": "Eleven skal variere språket sitt.",
  "criteria_repetition_text": "Dette ordet gjentar du mange ganger.",
  "criteria_repetition_title": "Variert språk",
  "criteria_passive_voice_student_description": "Skrible sier ifra når du skriver en passivsetning. Du vurderer selv om det er best å skrive om setningen.",
  "criteria_passive_voice_subtitle": "Vurder å skrive om",
  "criteria_passive_voice_teacher_description": "Skrible sier ifra når eleven skriver en passivsetning. Eleven skal vurdere om det er best å skrive om setningen.",
  "criteria_passive_voice_text": "Dette ser ut som en passivsetning. Den kan være vanskelig å lese. Hvis det er viktig å få fram hvem som utfører handlingen, kan du skrive om.",
  "criteria_passive_voice_title": "Passiver",
  "criteria_space_count_student_description": "Du skal ha bare ett mellomrom mellom ord.",
  "criteria_space_count_subtitle": "Fjern ekstra mellomrom",
  "criteria_space_count_teacher_description": "Eleven skal ha bare ett mellomrom mellom ord.",
  "criteria_space_count_text": "Det ser ut som du bruker for mange mellomrom her:",
  "criteria_space_count_title": "Mellomrom mellom ord",
  "criteria_spellcheck_student_description": "Du skal bruke reglene for skriftlig bokmål og nynorsk.",
  "criteria_spellcheck_subtitle": "Se over stavemåten",
  "criteria_spellcheck_teacher_description": "Eleven skal bruke reglene for skriftlig bokmål og nynorsk.",
  "criteria_spellcheck_text": "Det ser ut som dette ordet er stavet feil. Her er noen forslag som kan hjelpe deg:",
  "criteria_spellcheck_text_no_suggestions": "Vi har ikke dette ordet i ordlista. Du kan ignorere det ved å trykke på knappen nede i venstre hjørne.",
  "criteria_spellcheck_title": "Rettskriving",
  "criteria_possessives_student_description": "Du skal bøye eiendomsord (for eksempel «min», «mitt», «din», «sin») riktig.",
  "criteria_possessives_subtitle": "Bøy ordet, så det passer",
  "criteria_possessives_teacher_description": "Eleven skal bøye eiendomsord (for eksempel «min», «mitt», «din», «sin») riktig.",
  "criteria_possessives_text": "Det ser ut som dette ordet er bøyd feil.",
  "criteria_possessives_title": "Eiendomsord",
  "criteria_og_aa_student_description": "Du skal bruke «og» eller «å» riktig.",
  "criteria_og_aa_subtitle": "Se over stavemåten",
  "criteria_og_aa_teacher_description": "Eleven skal bruke «og» eller «å» riktig.",
  "criteria_og_aa_text": "Det ser ut som dette ordet er brukt feil. Her er et forslag:",
  "criteria_og_aa_title": "«Å» eller «og»",
  "criteria_structure_student_description": "Du skal dele teksten inn i innledning, hoveddel og avslutning.",
  "criteria_structure_teacher_description": "Eleven skal dele teksten inn i innledning, hoveddel og avslutning.",
  "criteria_structure_title": "Tekstoppbygging",
  "criteria_terms_student_description": "Du skal nevne utvalgte ord i teksten din.",
  "criteria_terms_student_goals_description": "Du skal nevne disse ordene i teksten din:",
  "criteria_terms_subtitle": {
    "one": "{{count}}/{{numberRequired}} er nevnt",
    "other": "{{count}}/{{numberRequired}} er nevnt"
  },
  "criteria_terms_success_text": "Bra jobba! Du har nevnt alle ordene.",
  "criteria_terms_teacher_description": "Eleven skal nevne utvalgte ord i teksten sin. Husk å bruke oppslagsformen av ordet – det ubøyde ordet. Trykk enter for å legge til et ord.",
  "criteria_terms_teacher_goals_description": "Eleven skal nevne disse ordene i teksten sin:",
  "criteria_terms_text": "Du har nevnt {{numberUsed}} av {{numberRequired}} ord.",
  "criteria_terms_title": "Ord du skal bruke i teksten",
  "criteria_text_structure_text": "Har du delt opp teksten din i innledning, hoveddel og avslutning?",
  "criteria_text_structure_title": "Tekstoppbygging",
  "criteria_title_student_description": "Du skal skrive en overskrift som vekker interesse.",
  "criteria_title_teacher_description": "Eleven skal skrive en overskrift som vekker interesse.",
  "criteria_title_text": "Har du skrevet en overskrift som gjør leseren interessert?",
  "criteria_title_title": "Overskrift",
  "criteria_type_communication_label": "Kommunikasjon",
  "criteria_type_communication_text_student": "Gir overskriften nyttig informasjon om hva teksten handler om? Får leseren lyst til å lese videre? Skrible minner deg på å sjekke om overskriften er interessant. Læreren din vurderer i tillegg hvor godt teksten din er tilpasset leseren og sjangeren.",
  "criteria_type_communication_text_teacher": "Skrible minner eleven på å sjekke om overskriften er interessant.\n\nI tillegg kan du vurdere hvor godt teksten er tilpasset leseren og sjangeren:\n- Kommuniserer teksten med leseren på en relevant måte?\n- Har eleven valgt en skriverposisjon som passer til sjangeren?\n- Har teksten en overskrift som vekker interesse og orienterer leseren på en god måte?",
  "criteria_type_content_label": "Innhold",
  "criteria_type_content_text_student": "Skrible vurderer om teksten din inneholder riktig antall ord. Har læreren din bedt deg bruke visse ord i teksten, sjekker Skrible om de er med.",
  "criteria_type_content_text_teacher": "Skrible vurderer om teksten inneholder riktig antall ord. Har du bedt eleven bruke visse ord i teksten, sjekker Skrible om de er med.\n\nI tillegg kan du vurdere dette: \n- Er innholdet relevant og utdypet?\n- Hvordan fremstiller eleven tanker, meninger, synspunkter eller kunnskapsområder?",
  "criteria_type_intro_link_student": "Skrivesenterets forventningsnormer",
  "criteria_type_intro_link_teacher": "Skrivesenterets forventningsnormer",
  "criteria_type_intro_student": "Vurderingskriteriene og beskrivelsene er inspirert av {{link}}.",
  "criteria_type_intro_teacher": "Vurderingskriteriene og beskrivelsene er inspirert av {{link}}.",
  "criteria_type_language_label": "Språkbruk",
  "criteria_type_language_text_student": "Skrible gir deg tips hvis ett eller flere ord brukes for mye i teksten. I tillegg sier Skrible fra hvis setningene du skriver, er for lange. En setning på mer enn 20 ord regnes som lang. \nSkrible sier også ifra om du skriver passive setninger. Du vurderer selv om det er best å skrive om setningene, hvis det er viktig å få fram hvem som utfører handlingen.",
  "criteria_type_language_text_teacher": "Skrible gir eleven tips dersom ett eller flere ord brukes for mye i teksten. I tillegg sier Skrible fra hvis setningene blir for lange.\n\nSkrible sier også ifra om eleven skriver passive setninger. Eleven vurderer selv om det er best å skrive om setningene, hvis det er viktig å få fram hvem som utfører handlingen.\n\nDu kan i tillegg vurdere dette: \n- Hvor variert, relevant og presist er ordvalget, den språklige stilen og strukturen på setningene? Bruker eleven fagbegreper? Varierer eleven setningsstrukturen, for eksempel ved å bruke leddsetninger?\n- Bruker eleven varierte språklige virkemidler?",
  "criteria_type_punctuation_label": "Tegnsetting",
  "criteria_type_punctuation_text_student": "Skrible hjelper deg med riktig tegnsetting og sier ifra hvis\n- et spørsmål mangler spørsmålstegn\n- en overskrift har punktum\n- komma foran «men» mangler\n- teksten inneholder for mange utropstegn",
  "criteria_type_punctuation_text_teacher": "Skrible hjelper eleven med riktig tegnsetting og sier ifra hvis\n- et spørsmål mangler spørsmålstegn\n- en overskrift har punktum\n- komma foran «men» mangler\n- teksten inneholder for mange utropstegn \n\nI tillegg kan du vurdere dette: \n- Behersker eleven bruk av punktum, utropstegn, spørsmålstegn, kolon, parentes og bindestrek?\n- Er komma brukt ved oppramsing, mellom helsetninger og etter leddsetninger?\n- Hvis direkte tale er brukt: Er det markert?",
  "criteria_type_spelling_label": "Skriveregler",
  "criteria_type_spelling_text_student": "Skrible veileder deg mens du skriver. Du får beskjed hvis et ord er stavet feil. Du får også beskjed hvis et egennavn (for eksempel navn på en person, et land eller et firma) mangler stor forbokstav. Skrible kan sjekke om du har for mange mellomrom mellom ord.\n\nSkrible sjekker om du bøyer eiendomsord riktig i en setning. Eiendomsord er ord som for eksempel «min», «mitt», «din», «våre». Skrible kan gi beskjed om du følger reglene for «og/å».",
  "criteria_type_spelling_text_teacher": "Skrible veileder eleven i skrivingen og gir beskjed hvis et ord er stavet feil. Eleven får også beskjed hvis et egennavn (for eksempel navn på en person, et land eller et firma) mangler stor forbokstav, eller hvis det er for mange mellomrom mellom ord.\n\nSkrible vurderer om eleven bøyer eiendomsord riktig i en setning (for eksempel «min», «mitt», «din», «våre»).\n\nSkrible sjekker også om elevene følger reglene for «og/å».\n\nI tillegg kan du vurdere dette: \n- Mestrer eleven de fleste rettskrivingsreglene for bokmål eller nynorsk? Har eleven få eller mange skrivefeil?\n- Skriver eleven sammensatte ord riktig (for eksempel «vannflaske», ikke «vann flaske»)?\n- Bøyer eleven ord riktig?\n- Følger eleven reglene for «da/når»?",
  "criteria_type_structure_label": "Struktur",
  "criteria_type_structure_text_student": "Skrible minner deg på å dele teksten inn i innledning, hoveddel og avslutning. I tillegg sier Skrible ifra hvis avsnittene er for lange. Et avsnitt på mer enn 150 ord regnes som langt.",
  "criteria_type_structure_text_teacher": "Skrible minner eleven på å dele teksten inn i innledning, hoveddel og avslutning. I tillegg sier Skrible ifra hvis avsnittene er for lange. Et avsnitt på mer enn 150 ord regnes som langt.\n\nDu kan i tillegg vurdere dette:  \n- Er strukturen tilpasset sjangeren?\n- Har teksten tematisk sammenheng?\n- Har teksten passe lange avsnitt?\n- Bruker eleven varierte koblingsmarkører for å skape sammenheng i teksten (for eksempel «fordi, men, dersom, allikevel»)?",
  "criteria_word_count_evaluation_over": "Du har skrevet for mange ord. Teksten skal inneholde",
  "criteria_word_count_evaluation_remaining": "Skriv {{remainingCount}} ord for å nå målet om",
  "criteria_word_count_evaluation_success": "Du har nådd målet om å skrive",
  "criteria_word_count_student_description": "Du skal tilpasse mengden innhold til det teksten handler om.",
  "criteria_word_count_student_goals_description": "Du skal skrive {{minCount}}–{{maxCount}} ord.",
  "criteria_word_count_teacher_description": "Eleven skal tilpasse mengden innhold til det teksten handler om. Når du velger dette kriteriet, må du huske å fylle inn minimum og maksimum antall ord.",
  "criteria_word_count_teacher_goals_description": "Eleven skal skrive {{minCount}}–{{maxCount}} ord.",
  "criteria_word_count_title": "Antall ord",
  "tools": {
    "title": "Verktøy",
    "autocorrect": {
      "title": "Autokorrektur",
      "teacher_description": "Eleven kan bruke autokorrektur i skriveoppdraget. Eleven kan trykke på riktig forslag i stavekontrollen, og ordet blir automatisk endret i teksten.",
      "student_description": "Du kan bruke autokorrektur i dette skriveoppdraget.",
    },
    "writing_frame": {
      "none": "Du kan ikke bruke en skriveramme i dette oppdraget.",
      "title": "Skriveramme",
      "teacher_description": "Eleven kan bruke en skriveramme i skriveoppdraget.",
      "student_description": "Du kan bruke en skriveramme i dette oppdraget.",
      "teacher_goals_description": "Eleven kan bruke skriverammen «{{writingFrame}}» i dette skriveoppdraget.",
      "student_goals_description": "Du kan bruke skriverammen «{{writingFrame}}» i dette skriveoppdraget.",
    },
  },
  "error_page_404_headline": "Skrible finner ikke denne siden",
  "error_page_404_body": "Dette er en side som er flyttet eller slettet, eller som du ikke har tilgang til.",
  "error_page_404_button": "Gå tilbake",
  "not_found_404_headline": "Skrible finner ikke denne siden",
  "not_found_404_body": "Dette er en side som er flyttet eller slettet, eller som du ikke har tilgang til.",
  "not_found_404_button": "Gå tilbake",
  "error_page_500_headline_1": "Beklager!",
  "error_page_500_headline_2": "Skrible mistet kontakten",
  "error_page_500_body": "Prøv å laste siden på nytt.",
  "error_page_500_button": "Prøv igjen",
  "error_modal_500_headline": "Er du sikker på at du vil gå ut av siden?",
  "error_modal_500_body": "Du har ulagrede endringer på siden. Går du ut av siden nå, kan du miste endringene dine. ",
  "error_modal_500_primary_btn": "Bli på siden",
  "error_modal_500_secondary_btn": "Gå ut og forkast endringene",
  "field_comment_placeholder": "Skriv en kommentar",
  "field_deadline_placeholder": "Sett en frist",
  "field_general_review_placeholder": "Gi tilbakemelding",
  "field_group_placeholder": "Velg en klasse",
  "field_learning_goals_count": {
    "one": "{{count}} kompetansemål",
    "other": "{{count}} kompetansemål"
  },
  "field_learning_goals_placeholder": "Legg til kompetansemål",
  "field_missing": "Du må fylle inn de obligatoriske feltene før du kan lagre.",
  "field_optional": "(valgfritt)",
  "field_writing_frame_placeholder": "Velg skriveramme",
  "field_writing_frame_selected": "Skriveramme: «{{writingFrame}}»",
  "footer_headline": "Skrible",
  //"footer_body_text": "Et digitalt skriveverktøy fra",
  "footer_body_text": "Et digitalt skriveverktøy levert av",
  "footer_link_text": "Personvernerklæring",
  "footer_link_ci": "Informasjonskapsler",
  "footer_link_accessibility_statement": "Tilgjengelighetserklæring",
  "about_us": {
    "title": "Om oss",
    "description": "Skrible er utviklet og eid av KF og NTB Arkitekst AS i partnerskap.",
    "kf_description": "KF er et firma som jobber kontinuerlig med å utvikle løsninger som skal gjøre hverdagen til innbyggerne i Norge enklere.\n\nKF har allerede alle kommuner i Norge på kundelisten. Som en av Norges mest innovative bedrifter tar KF teknologi som chatboter og VR ut i norske kommuner.\n\nNå satser KF i utdanningsmarkedet og lager nye læremidler som skal trene grunnleggende ferdigheter. KF har flere pedagoger blant de ansatte som sørger for at elevenes, lærernes og skolenes behov blir ivaretatt i utviklingen.",
    "kf_read_more_button": "Les mer om KF her",
    "ntb_arkitekst_description": "NTB Arkitekst er et språkkonsulentfirma som gjennom de siste 20 årene har jobbet variert med språk og tekst og har ekspertise på blant annet klarspråk, juridisk språk og digitale og byråkratiske tekster.\n\nNTB Arkitekst har jobbet spesielt mye med ulike tekster rettet mot elever og lærere i grunnskolen og videregående opplæring, og denne erfaringen sammen med klarspråksvinklingen har vært viktig i utviklingen av Skrible.\n\nNTB Arkitekst er et datterselskap til nyhetsbyrået NTB, og i videreutviklingen av Skrible bruker vi blant annet utviklere fra NTB.",
    "ntb_arkitekst_read_more_button": "Les mer om NTB Arkitekst her",
    "alt_text_1": "Dame med briller som smiler.",
    "alt_text_2": "Mann som smiler.",
  },
  "home_page": {
    "title": "Bli bedre til å skrive,",
    "title_highlight": "ett ord om gangen",
    "how_it_works": {
      "title_1": "Sånn",
      "title_2": "fungerer",
      "title_3": "Skrible",
      "step_1_title": "Lag et skriveoppdrag",
      "step_1_description": "Når du bruker Skrible, kan du lage skriveoppdrag i alle fag og plukke ferdige maler fra Skribles oppdragsbank.\n\nVelg hvilke vurderingskriterier som gjelder for skriveoppdraget, og bestem hvilke kompetansemål elevene skal jobbe med. Skrible gjør det enkelt å tilpasse vurderingen til hver elev.",
      "step_1_alt_text": "PC som viser Skrible-siden for å lage et nytt skriveoppdrag.",
      "step_2_title": "Gi elevene skrivero og veiledning",
      "step_2_description": "Skrible analyserer elevenes tekst mens de skriver. Elevene velger selv når de vil bruke vurderingen og veiledningen fra Skrible, og må alltid endre teksten sin selv.\n\nTrenger eleven litt ekstra skrivero, er det bare å slå på fokusmodus.",
      "step_2_alt_text": "PC som viser Skrible-siden med statusoversikt over skriveoppdrag.",
      "step_3_title": "Spar tid og gi dypere tilbakemeldinger",
      "step_3_description": "Hele skriveprosessen skjer i Skrible – fra du deler ut oppdraget, til du vurderer teksten. Det sparer tid og frustrasjon.\n\nNår elevene er ferdige med teksten sin, har de allerede vurdert oppbyggingen, rettskrivingen, språket og tegnsettingen sin. Det gir deg mer tid til å se på helheten og gå dypere inn i både teksten og tilbakemeldingene.",
      "step_3_alt_text": "PC som viser elevens Skrible-side for å redigere skriveoppdrag og tilbakemelding fra lærer.",
      "increase_image_size": "Trykk for å øke størrelsen på bildet",
      "reduce_image_size": "Trykk for å redusere størrelsen på bildet"
    },
    "what_skrible_helps_you_with": {
      "title_1": "Hva",
      "title_2": "hjelper",
      "title_3": "Skrible med?",
      "description": "Skrible er et skriveverktøy som både er pedagogisk og får deg til å spare tid.",
      "alt_text": "Dame med rosa genser som sitter med beina i kors.",
      "step_1_title": "Bedre skriveopplevelse i alle fag",
      "step_1_description": "Skrible er et skriveverktøy som eleven og læreren bruker direkte i nettleseren. Der jobber de sammen med teknologien for å gjøre skriveprosessen mer lærerik og effektiv i alle fag, og læreren kan fokusere på den individuelle tilbakemeldingen til hver elev.",
      "step_2_title": "Elevene lærer mer med egenvurdering",
      "step_2_description": "Skrible er et smartere skriveverktøy. Det veileder eleven til å gjøre teksten bedre, og eleven må hele tida ta aktive valg.",
      "step_3_title": "Elevene får verdifull støtte",
      "step_3_description": "Vi kombinerer pedagogikk, språkteknologi og klarspråk. Slik får eleven verdifull støtte og opplæring i å skrive – ikke bare riktig, men også godt.",
      "step_4_title": "Skrible er like god på bokmål og nynorsk",
      "step_4_description": "Skrible er utvikla på bokmål og nynorsk parallelt og fungerer like godt på begge målformer.",
    },
  },
  "info_about_skrible": {
    "title_1": "Hvorfor",
    "title_2": "velge Skrible i undervisningen?",
    "description": "Skrible er et digitalt skriveverktøy for lærere og elever fra 5. trinn og oppover som kombinerer språkteknologi og pedagogikk. Med Skrible får elever og lærere en enklere, mer lærerik og smartere skriveprosess.",
    "alt_text": "Gutt med briller som rekker opp hånda.",
    "for_teachers": {
      "title_1": "For deg som skal",
      "title_2": "undervise",
      "step_1_title": "En ny og lærerik skriveopplevelse for elevene dine",
      "step_1_description": "Skrible gir elevene vennlige tips underveis. Tilbakemeldingene gir elevene mer å jobbe med enn bare rettskriving, og Skrible kan sjekke alt fra ordstilling til setningsoppbygging og inndeling i avsnitt.\n\nSkrible minner elevene på utfordringene i teksten, men Skrible retter ingenting automatisk – elevene må alltid gjøre endringene selv. På den måten er elevene aktive gjennom hele skriveprosessen, de vurderer eget arbeid underveis og får en mer lærerik skriveopplevelse.",
      "step_2_title": "Smartere skriveprosess frigjør tid for læreren",
      "step_2_description": "Skrible hjelper deg, men du er fortsatt sjef og bestemmer hva elevene skal øve på når de skriver. Hele klassen jobber med samme oppgave, men hver enkelt elev kan ha unikt skrivefokus.\n\nDu kan følge med og gi tilbakemeldinger underveis, men siden Skrible hjelper eleven, er mye av vurderingsjobben unnagjort når du mottar teksten. Nå kan du spare tid og fokusere på det viktigste i klasserommet, nemlig oppfølging av hver enkelt elev.",
      "step_3_title": "Ta i bruk Skrible på 1, 2, 3",
      "step_3_description": "Du trenger ikke timevis med opplæring for å ta i bruk Skrible. Alle, både elever og lærere, tar verktøyet enkelt i bruk, og du har god tilgang på støtte og veiledning fra oss i Skrible. Vi er her hvis du trenger oss, enten på hjelpesidene våre, på YouTube-kanalen vår eller på e-post.",
    },
    "for_administrators": {
      "title_1": "For deg som",
      "title_2": "kjøper inn",
      "step_1_title": "Alle fag",
      "step_1_description": "Elevene skal skrive i alle fag, og derfor finner du alle kompetansemålene fra den nye læreplanen i Skrible. Alle målene kan knyttes til skrivearbeidet, også mål fra ulike fag.",
      "step_2_title": "Tilpasset opplæring",
      "step_2_description": "Vi har gjort det enkelt for lærerne å tilpasse skriveopplæringen til hver enkelt elev. På den måten kan dere enkelt sørge for at alle elever skriver på sitt eget nivå.",
      "step_3_title": "Vurdering for læring",
      "step_3_description": "Vurdering for læring er et viktig prinsipp i Skrible. Skrible leder elevene gjennom en kontinuerlig egenvurdering. Lærerne holder følge med prosessen og kan både gi vurdering underveis og til slutt.",
      "step_4_title": "Enkelt å kjøpe, enkelt å bruke",
      "step_4_description": "Prosessen fra kjøp til bruk er ukomplisert og trygg. Skrible og skolen eller kommunen blir sammen enige om implementeringen, og vi er alltid her for å hjelpe dere.\n\nAlle som bruker Skrible, kan være sikre på at personvernet er i trygge hender. Selskapene bak Skrible – KF og NTB Arkitekst – har lang erfaring med å levere solide produkter til fornøyde brukere.",
    },
    "for_parents": {
      "title_1": "For",
      "title_2": "foresatte",
      "step_1_title": "Skriv sammen med barnet ditt",
      "step_1_description": "La barnet ditt logge inn i Skrible og vise hvordan det går med skriveoppdragene. Barnet er logget inn med elevkontoen, og dere kan sammen utforske skriveoppdragene lærerne har gitt. Les gjerne opp teksten eller tilbakemeldingene høyt og snakk sammen om teksten.",
      "step_2_title": "Vurder tilbakemeldingene Skrible gir ",
      "step_2_description": "Skrible leder elevene gjennom en kontinuerlig egenvurdering. Du kan gå gjennom tilbakemeldingene Skrible gir mens eleven skriver. Skryt av at barnet ditt jobber steg for steg, resultatene kommer.",
      "step_3_title": "Skrible er tilgjengelig",
      "step_3_description": "Universell utforming er et viktig prinsipp for oss, vi jobber hele tiden for at Skrible skal være tilgjengelig for alle. ",
      "step_3_url": "Her er tilgjengelighetserklæringen til Skrible.",
    },
    "what_teachers_say": {
     "title_1" : "Hva lærere",
     "title_2": "sier",
     "quote": "«Med Skrible kan eleven og læreren få bedre tid og fokus på det jeg tror er mest meningsfullt og motiverende for begge – innhold og formidling»",
     "quotist": "lærer på ungdomsskolen",
     "alt_text": "Dame med hjerteformede øyne som smiler og peker på lærersitatet.",
    },
  },
  "pricing": {
    "title": "Veiledende priser",
    "description": "Alle prisene er ekskl. mva., og de gjelder for 12 måneder.",
    "school": "Skole",
    "school_price": "79 kr per elev",
    "school_description": "for lærere og elever på én eller flere skoler",
    "municipality": "Kommune",
    "municipality_price": "75 kr per elev",
    "municipality_description": "for lærere og elever i en kommune",
    "alt_text": "Smilende lærer med bærbar PC.",
    "where_to_buy": "Du kan kjøpe Skrible direkte, gjennom Pendel eller gjennom Skolon",
    "button_pendel": "Gå til Pendel",
    "button_skolon": "Gå til Skolon",
    "comparison_list": {
      "freemium_title": "Freemium",
      "skrible_title": "Skrible",
      "freemium_version": "testversjon",
      "skrible_version": "fullversjon",
      "you_can": "Du kan",
      "included": "Inkludert",
      "not_included": "Ikke inkludert",
      "point1": "Lage skriveoppdrag elevene kan jobbe med",
      "point2": "Velge vurderingskriterier til et skriveoppdrag",
      "point3": "Legge til kompetansemål",
      "point4": "Få tilgang til alle de nyeste vurderingskriteriene",
      "point5": "Tilpasse vurderingskriterier til hver elev",
      "point6": "Legge til skriverammer og setningsstartere",
      "point7": "Legge til verktøyet autokorrektur ",
      "point8": "Redigere skriveoppdraget etter at du har publisert det",
      "point9": "Gi skriveoppdrag til hele klassen",
      "point10": "Logge inn og se alle Feide-gruppene dine",
      "point11": "Få oversikt over alle skriveoppdragene dine",
    },
  },
  "try_skrible": {
    "title_1": "Prøv",
    "title_2": "Skrible gratis",
    "description": "Her kan du prøve ut en del av funksjonene i Skrible helt gratis. Du kan både teste hvordan du lager et skriveoppdrag, og dele det med elevene dine. Elevene dine svarer på skriveoppdraget og sender det inn til deg.\n\nSkrible er laget for å fungere på nettbrett og PC.",
    "step_1_title": "Lag et skriveoppdrag",
    "step_1_description": "Når du bruker Skrible, kan du lage skriveoppdrag i alle fag til alle elevene dine.\n\nVelg hvilke vurderingskriterier som gjelder for skriveoppdraget, og bestem hvilke kompetansemål elevene skal jobbe med.\n\nI prøveversjonen kan du bare teste ut noen av vurderingskriteriene, og du kan ikke tilpasse vurderingskriteriene til hver elev.",
    "step_2_title": "Del skriveoppdraget",
    "step_2_description": "Du får en lenke til skriveoppdraget, som du kan dele med elevene dine. Elevene får automatisk et brukernavn og svarer på skriveoppdraget. Så sender de det inn til deg.",
    "step_3_title": "Vurder skriveoppdraget",
    "step_3_description": "Du får oversikt over hvilke elever som har levert og kan vurdere skriveoppdragene direkte i Skrible.",
    "alt_text": "Smilende person med briller som holder en mobiltelefon.",
    "create_assignment": "Lag et skriveoppdrag",
  },
  "label_all_groups": "Alle klasser",
  "label_assignment_draft": "Utkast",
  "label_assignment_tools": "Verktøy",
  "label_assignments": "Skriveoppdrag",
  "label_assignments_submitted": "{{count}}/{{total}} sendt inn",
  "label_autosave_success": "Alle endringer er lagret",
  "label_by": "av",
  "label_class": "Velg klasse",
  "label_completed": "Ferdig",
  "label_deadline": "Frist",
  "label_description": "Beskrivelse",
  "label_edit_text_tooltip": "Endre teksten din selv",
  "label_evaluation_criteria": "Vurderingskriterier",
  "label_evaluation_criteria_count": {
    "one": "{{count}} vurderingskriterium",
    "other": "{{count}} vurderingskriterier"
  },
  "label_filter_learning_goals": "Søk i kompetansemål …",
  "label_filter_discipline": "Søk i fag …",
  "label_filter_learning_goals_empty": "Ingen treff. Sjekk om ordet er stavet riktig, eller søk på noe annet.",
  "label_filter_learning_goal_no_filter": "Du må velge klasse eller fag før du kan laste inn kompetansemål.",
  "label_filter_learning_goal_no_filter_selected": "Du må velge klasse eller fag for å laste inn flere kompetansemål.",
  "label_filter_discipline_empty": "Ingen treff.",
  "label_general_review": "Generell tilbakemelding",
  "label_group": "Klasse",
  "label_hide_interface": "Gå inn i fokusmodus",
  "label_hide_writing_frame": "Skjul skriverammen",
  "label_hjelp": "Hjelp",
  "label_information": "Til læreren",
  "label_learning_goals": "Kompetansemål",
  "label_new_assignment": "Nytt skriveoppdrag",
  "label_page_not_found": "Vi finner ikke denne siden.",
  "label_personalize_criteria_picker": "Tilpass vurderingskriteriet til hver elev",
  "label_personalize_tools_picker": "Tilpass verktøyet til hver elev",
  "label_profile": "Profil",
  "label_review": "Vurdering",
  "label_search": "Søk",
  "label_settings": "Innstillinger",
  "label_show_all": "Se alle fag",
  "label_show_interface": "Gå ut av fokusmodus",
  "label_show_writing_frame": "Vis skriverammen",
  "label_students": "Elever",
  "label_submissions": "Innleveringer",
  "label_tab_all": "Alle",
  "label_tab_assignment": "Skriveoppdraget",
  "label_tab_assignment_bank": "Oppdragsbank",
  "label_tab_writing_frames": "Skriverammemaler",
  "label_tab_completed": "Ferdige",
  "label_tab_drafts": "Utkast",
  "label_tab_open": "Åpne",
  "label_tab_student_assignment": "Skriveoppdrag",
  "label_tab_student_text": "Teksten din",
  "label_tab_submissions": "Innleveringer",
  "label_teacher": "Lærer",
  "label_teacher_visibility_learning_goals": "Bare lærere kan se kompetansemålene",
  "label_texts": "Skriveoppdrag",
  "label_website_map": "Nettstedskart",
  "label_words": "ord",
  "label_words_count": "{{count}} ord",
  "lang_page_description": "Du kan bytte målform når som helst ved å klikke på profilikonet i høyre hjørne.",
  "lang_page_title": "Nå bruker du Skrible på bokmål.",
  "language": {
    "nynorsk": "Nynorsk",
    "bokmaal": "Bokmål",
  },
  "link_bokmaal": "Bokmål",
  "link_contact": "Kontakt oss",
  "link_help": "Hjelp",
  "link_nynorsk": "Nynorsk",
  "link_pricing": "Priser",
  "link_signup_consent": "brukervilkårene for Skrible",
  "link_what_is_skrible": "Hvorfor Skrible?",
  "link_about_us": "Om oss",
  "modal_assignment_completed_student_text": "Bra jobba! Læreren din har vurdert teksten. Skriveoppdraget er fullført!",
  "modal_assignment_completed_teacher_text": "Bra jobba! Du har nå vurdert teksten. Skriveoppdraget er fullført!",
  "modal_button_submit_for_review": "Send inn",
  "modal_create_draft_text": "Du kan lagre et utkast til skriveoppdraget og finne det igjen i listen med utkast.",
  "modal_create_draft_title": "Vil du lagre skriveoppdraget som et utkast?",
  "modal_learning_goals_title": "{{count}} kompetansemål",
  "modal_no_learning_goals_title": "Velg kompetansemål for skriveoppdraget",
  "modal_personalized_criteria_subtitle": "Tilpass vurderingskriteriet til hver elev",
  "modal_personalized_tools_subtitle": "Tilpass verktøyet til hver elev",
  "modal_publish_assignment_text": "Når du har lagt ut skriveoppdraget, kan elevene begynne å skrive.",
  "modal_publish_assignment_title": "Vil du legge ut skriveoppdraget?",
  "modal_submit_review_text": "Når du har sendt vurderingen, kan {{studentName}} se over og endre teksten sin.",
  "modal_submit_review_title": "Gi vurderingen din",
  "modal_text_completed_text": "Bra jobba, skriveoppdraget er fullført!",
  "modal_text_completed_title": "Ferdig",
  "modal_text_in_evaluation_text": "Nå vurderer {{name}} teksten sin.",
  "modal_text_in_evaluation_title": "Blir vurdert av {{name}}",
  "modal_text_in_review_after_deadline_text": "Teksten din blir nå vurdert av læreren din {{teacherName}}.",
  "modal_text_in_review_text": "Teksten er sendt inn til vurdering. Nå kan læreren læreren din {{teacherName}} se på teksten og vurdere den på nytt",
  "modal_text_in_review_title": "Blir vurdert av læreren",
  "modal_text_open_text": "Du har ikke åpnet teksten ennå.",
  "modal_text_submitted_text": "Når fristen har gått ut, kommer læreren din til å vurdere teksten.",
  "modal_text_submitted_title": "Du har levert skriveoppdraget",
  "modal_title_delete_assignment": "Er du sikker på at du vil slette skriveoppdraget?",
  "modal_title_delete_comment": "Er du sikker på at du vil slette kommentaren?",
  "modal_title_delete_general_review": "Er du sikker på at du vil slette tilbakemeldingen?",
  "modal_text_delete_assignment": "Det blir slettet permanent, og du kan ikke gjenopprette det.",
  "modal_text_delete_comment": "Kommentaren blir slettet permanent, og du kan ikke gjenopprette den.",
  "modal_text_delete_general_review": "Tilbakemeldingen blir slettet permanent, og du kan ikke gjenopprette den.",
  "modal_alt_button_delete": "Slett",
  "modal_primary_button_delete": "Avbryt",
  "modal_update_draft_text": "Du kan lagre en oppdatert versjon av utkastet, eller oppdatere utkastet og legge det ut.",
  "modal_update_draft_title": "Du kan lagre en oppdatert versjon av utkastet, eller oppdatere utkastet og legge det ut.",
  "navigation_button_separator": "eller",
  "open_texts_not_available": "Du har ingen åpne skriveoppdrag",
  "radio_button_correct": "Riktig",
  "radio_button_wrong": "Feil",
  "state_completed_label": "Ferdig",
  "state_draft_label": "Utkast",
  "state_past_deadline_label": "Fristen er ute",
  "state_in_review_student_label": "Blir vurdert",
  "state_new_review_student_label": "Ny vurdering",
  "state_new_submission_label": "Ny innlevering",
  "state_not_submitted_label": "Ikke levert ennå",
  "state_ready_to_submit_student_label": "Klar til å sende inn?",
  "state_submitted_label": "Sendt inn til vurdering",
  "text_alt_illustration_kontakt": "Dame som kikker spørrende og rekker opp hånda.",
  "text_alt_illustration_solid": "Flere S-bokstaver i blått, turkis, grønt, limegrønt, oransje og rosa danner en sirkel.",
  "text_choose_language": "Velg målform:",
  "text_contact1_content_header": "Har du spørsmål?",
  "text_contact2_content_header": "Trenger du hjelp?",
  "text_contact1_content_text": "Vi svarer gjerne på spørsmål om prøveperiode, kjøp, tilgang på din skole eller noe annet du lurer på.\n\nVi presenterer gjerne Skrible i et videomøte, bare ta kontakt!",
  "text_contact2_content_text": "Vi hjelper deg med hvordan du logger på, lager oppgaver, vurderer tekster og mye annet i Skrible.",
  "text_contact_primary_heading_1": "Kontakt",
  "text_contact_primary_heading_2": "oss",
  "text_contact_secondary_heading": "Send oss en e-post, så svarer vi deg så fort vi kan.",
  "text_content_placeholder": "Skriv teksten din her",
  "text_error_signup_form_consent": "Du må akseptere brukervilkårene for å fortsette",
  "text_error_signup_form_email": "Du må fylle ut e-postadresse",
  "text_error_signup_form_municipality": "Du må fylle ut kommune",
  "text_error_signup_form_name": "Du må fylle ut fornavn og etternavn",
  "text_error_signup_form_school": "Du må fylle ut skole",
  "text_feedback_modal_bottom": "Takk for tilbakemeldingen! \nVi bruker tilbakemeldingene til å forbedre språkmotoren i Skrible. Vi lagrer hele setningen du gir tilbakemelding på.",
  "text_feedback_modal_heading": "Tilbakemelding på forslag",
  "text_feedback_modal_radio_app": "Forslaget er:",
  "text_feedback_modal_radio_user": "Teksten min er:",
  "text_feedback_modal_success": "Takk for tilbakemeldingen!",
  "text_feedback_modal_textfield_head": "Har du andre tilbakemeldinger?",
  "text_feedback_modal_textfield_sub": "Dette er helt valgfritt.",
  "text_in_review_title": "Blir vurdert",
  "text_signup_confirmation_content": "Du får en bekreftelse på e-post når brukeren din i Skrible er aktivert. Det kan ta opp til én virkedag.",
  "text_signup_confirmation_main_heading": "Takk for at du vil prøve Skrible!",
  "text_signup_confirmation_secondary_heading": "Snart ligger Skrible i innboksen din.",
  "text_signup_consent": "Jeg samtykker til",
  "text_signup_content_part1": "Vi jobber fortsatt med å utvikle Skrible. Ved å prøve skriveverktøyet nå kan du være med og påvirke hva vi skal forbedre framover.",
  "text_signup_content_part2": "Lurer du på om skolen din allerede har tilgang til Skrible? Spør oss, så sjekker vi for deg!",
  "text_signup_content_part3": "Du må ha en Feide-bruker for å prøve Skrible. ",
  "text_signup_form_bottom": "Det er ingen bindingstid. Prøv betaversjonen av Skrible gratis frem til 1. oktober.",
  "text_signup_form_button": "Det er ingen bindingstid. Prøv betaversjonen av Skrible gratis frem til 1. oktober.",
  "text_signup_form_email": "E-post",
  "text_signup_form_municipality": "Kommune",
  "text_signup_form_name": "Fornavn og etternavn",
  "text_signup_form_school": "Skole",
  "text_signup_main_heading": "Bli kjent med Skrible!",
  "text_signup_secondary_heading": "Prøv Skrible med elevene dine frem til 1. oktober 2020.",
  "text_title_placeholder_student": "Tekstens overskrift",
  "text_title_placeholder_teacher": "Skriveoppdragets overskrift",
  "text_user_guide_body": "Hvis du ikke finner svar på det du lurer på, er det bare å sende oss en e-post. Vi hjelper deg videre!",
  "text_user_guide_primary_heading": "Hjelp og veiledning",
  "text_user_guide_secondary_heading": "Hva lurer du på?",
  "texts_not_available_student": "Du har ingen skriveoppdrag",
  "texts_not_available_teacher": "Eleven har ingen skriveoppdrag",
  "toolbar_bold": "Fet",
  "toolbar_heading_one": "Overskrift 1",
  "toolbar_heading_two": "Overskrift 2",
  "toolbar_italic": "Kursiv",
  "toolbar_link": "Lenke",
  "toolbar_link_placeholder": "Lim inn lenken:",
  "toolbar_list_ordered": "Nummerert liste",
  "toolbar_list_unordered": "Punktliste",
  "toolbar_strikethrough": "Gjennomstreking",
  "toolbar_underline": "Understreking",
  "assignment_filter":{
    "last_month": "De siste 30 dagene",
    "last_quart": "De siste 90 dagene",
    "last_half": "Det siste halvåret",
    "last_year": "Det siste året",
    "all": "Alle"
  },
  "writing_frame": {
    "about": "Om skriverammen «{{writingFrame}}»",
    "button": {
      "add_module": "Legg til ny",
      "copy": "Kopier og rediger skriverammen",
      "delete": "Slett skriverammen",
      "edit": "Rediger skriverammen",
      "new": "Ny skriveramme",
      "save": "Lagre skriverammen",
      "use": "Bruk skriverammen",
    },
    "created_by": "Laget av ",
    "skrible": "Skrible",
    "you": "Deg",
    "edit_writing_frame": "Rediger skriverammen",
    "label": {
      "description": "Infotekst om sjangeren",
      "language": "Målform",
      "module_size": "Størrelse på modulen",
      "sentence_starters": "Setningsstartere",
      "sentence_starters_tips": "Trykk Enter for å legge til en skrivestarter.",
      "subject": "Fag",
      "tips": "Tips",
      "title": "Tittel",
    },
    "filter": {
      "all_languages": "Begge målformer",
      "all_subjects": "Alle fag",
      "all_owners": "Laget av alle",
    },
    "modal_delete_writing_frame_title": "Er du sikker på at du vil slette skriverammen?",
    "modal_delete_writing_frame_text": "Den blir slettet permanent, og du kan ikke gjenopprette den.",
    "modules": "Moduler",
    "modules_tips": "Du kan endre rekkefølgen på modulene ved å dra i ikonene til venstre.",
    "modules_tips_2": "Trykk på mellomromstasten for å dra og slippe modulen, og bruk piltastene for å endre posisjonen til modulen.",
    "modules_edit": "Rediger modulen",
    "no_writing_frame": "Ingen skriveramme",
    "not_available": "Det finnes ingen slike skriverammer.",
    "placeholder": {
      "language": "Velg målform",
      "module_tips": "Skriv tips til eleven om modulen.",
      "module_title": "Skriv inn en tittel på modulen",
      "subject": "Velg fag",
    },
    "preview": "Forhåndsvisning",
    "preview_banner": "Forhåndsvisning – slik ser eleven skriverammen «{{writingFrame}}».",
    "size": {
      "small": "Liten",
      "medium": "Middels",
      "large": "Stor",
    },
    "search": "Søk i skriverammer ...",
    "tips_empty": "Her er det ingen tips.",
    // "tips_header": "Tips til «{{module}}»",
    "tips_sentence_starters": "Setningsstartere",
    "writing_frame": "Skriverammer",
  },
  /**
  * ASSIGNMENT EDITOR TEACHER
  */
  "criteria_word_count_minmax_error": "Du må fylle inn minimum og maksimum antall ord",
  "criteria_word_count_min_error": "Minimum antall ord må være større enn 0.",
  "criteria_word_count_max_error": "Maksimum antall ord må være større enn minimum.",
  "criteria_terms_error": "Du må legge til minst ett ord.",
  "tool_writing_frame_error": "Du har ikke valgt skriveramme.",
  "tooltip_remove_word": "Klikk for å fjerne ordet",
  /**
  * BUTTONS
  */
 "button_assignment_bank_nb": "Velg bokmål",
   "button_assignment_bank_nn": "Velg nynorsk",
  "button_back": "Gå tilbake",
  "button_burger_menu": "MENY",
  "button_cancel": "Avbryt",
  "button_cancel_changes": "Forkast endringene",
  "button_comment": "Kommenter",
  "button_confirm": "Legg til",
  "button_contact1_send_email": "Kontakt salg",
  "button_contact2_send_email": "Kontakt support",
  "button_create_draft": "Lagre som utkast",
  "button_delete": "Slett",
  "button_discard_draft": "Slett utkastet",
  "button_done": "Ferdig",
  "button_edit": "Rediger",
  "button_edit_draft": "Rediger utkastet",
  "button_export_pdf_only_text": "Lag en PDF med bare teksten",
  "button_export_pdf_with_evaluation": "Lag en PDF som har med Skribles vurdering",
  "button_export_pdf_with_evaluation_and_review": "Lag en PDF som har med både Skribles og lærerens vurdering",
  "button_export_pdf_with_review": "Lag en PDF som har med lærerens vurdering",
  "button_hide_evaluation": "Lukk",
  "button_lang_continue_nb": "Fortsett på bokmål",
  "button_lang_switch_nb": "Bytt til bokmål",
  "button_lang_switch_nn": "Bytt til nynorsk",
  "button_lang_switch_img_alt_text": "Det norske flagget",
  "button_loading": "Sender inn…",
  "button_loading_execute": "Utfører…",
  "button_loading_create": "Oppretter…",
  "button_loading_delete": "Sletter…",
  "button_loading_evaluation": "Sjekker…",
  "button_loading_saving": "Lagrer…",
  "button_log_out": "Logg ut",
  "button_login_with_feide": "Logg inn med Feide",
  "button_execute_feide_sync": "Oppdater fra Feide",
  "button_new_assignment": "Nytt skriveoppdrag",
  "button_open_bank": "Utforsk oppdragsbanken",
  "button_open_text": "Åpne teksten",
  "button_post": "Kommenter",
  "button_print": "Skriv ut",
  "button_publish": "Legg ut",
  "button_reopen_for_review": "Åpne igjen for vurdering",
  "button_restart_all": "Nullstill",
  "button_set_completed": "Marker som ferdig",
  "button_show_evaluation": "Sjekk teksten",
  "button_signup_form_submit": "Kom i gang",
  "button_start_writing": "Begynn å skrive",
  "button_submit_review": "Lever vurdering",
  "button_subscribe_newsletter": "Få nyhetsbrevet vårt",
  "button_try_for_free": "Prøv gratis",
  "button_update": "Oppdater",
  "button_update_and_publish_draft": "Oppdater og legg ut skriveoppdraget",
  "button_update_draft": "Oppdater utkastet",
  "button_user_feedback_cancel": "Lukk",
  "button_user_feedback_submit": "Send inn",
  "button_send_to_student": "Send til eleven",
  /**
  * DROPDOWN OPTIONS
  */
  "drop_down_option_cancel": "Avbryt",
  "drop_down_option_delete": "Slett",
  "drop_down_option_edit": "Rediger",
  "drop_down_option_mark_complete": "Marker som ferdig",
  "drop_down_option_publish": "Legg ut",
  "drop_down_option_reopen": "Åpne på nytt",
  "drop_down_option_send": "Send til eleven",
  "drop_down_option_open_assignment": "Åpne oppdraget",
  /**
  * MODAL REOPEN SUBMISSION
  */
  "modal_reopen_submission_title": "Åpne teksten på nytt",
  "modal_reopen_submission_content": "Eleven kan jobbe videre med teksten sin. Du kan ikke vurdere teksten før eleven har levert på nytt.",
  /**
  * MODAL COMPLETE SUBMISSION
  */
  "modal_complete_submission_title": "Vil du markere teksten som ferdig?",
  "modal_complete_submission_content": "Når du har markert teksten som ferdig, kan ikke eleven jobbe videre med den.",
  /**
  * MODAL REVIEW PREVIEW
  */
  "modal_review_preview_title": "Vil du vurdere teksten nå?",
  "modal_review_preview_content": "Når du vurderer teksten, kan ikke eleven jobbe videre med den før du har sendt vurderingen.",
  /**
  * MODAL UPDATE ASSIGNMENT
  */
  "modal_edit_assignment_text1": "Når du har oppdatert skriveoppdraget, kan elevene jobbe videre med det.",
  "modal_edit_assignment_text2" : "De jobber med den samme teksten sin.",
  "modal_edit_assignment_title": "Vil du oppdatere skriveoppdraget?",
  /**
  * MODAL MARK ASSIGNMENT COMPLETE
  */
  "modal_assignment_open_available_text": "Når du har markert skriveoppdraget som ferdig, kan ikke elevene jobbe videre med teksten sin.",
  "modal_assignment_open_not_available_text": "Du kan ikke vurdere skriveoppdraget før fristen har gått ut.",
  "modal_assignment_open_title": "Vil du markere skriveoppdraget som ferdig?",
  /**
  * MODAL RE-OPEN ASSIGNMENT
  */
  "modal_reopen_assignment_title": "Vil du åpne skriveoppdraget på nytt?",
  "modal_reopen_assignment_subtitle": "Sett ny frist",
  "modal_reopen_assignment_text_1": "Når du har lagt ut skriveoppdraget, kan elevene jobbe videre med teksten de har begynt på.",
  "modal_reopen_assignment_text_2": "Du må sette en ny frist for skriveoppdraget. Velg dato og tid i kalenderen.",
  /**
  * MODAL PUBLISH DRAFT
  */
  "modal_publish_draft_title_missing": "Bare litt igjen ...",
  "modal_publish_draft_text_missing": "Du må gi skriveoppdraget en tittel og beskrivelse, velge klasse og sette en frist før du kan legge det ut. Har du krysset av for skriveramme, må du gå inn og velge en skriveramme.",
  "modal_publish_draft_text": "Når du har lagt ut skriveoppdraget, kan elevene jobbe med det fram til fristen.",
  "modal_publish_draft_title": "Vil du legge ut skriveoppdraget?",
  /**
  * MODAL MISSING FIELDS
  */
    "modal_missing_fields_title": "Bare litt igjen ...",
    "modal_missing_fields_text": "Du må gi skriverammen en tittel og infotekst og velge fag og målform før du kan lagre.",
  /**
  * STATUS PAGES GLOBAL
  */
  "button_list_drop_down": "Valg",
  /**
  * TEACHER ASSIGNMENTS (OVERVIEW)
  */
  "tooltip_assignment_complete": "Ferdig!",
  "tooltip_assignment_draft": "Utkast",
  "tooltip_assignment_in_progress": "Underveis",
  /**
  * TEACHER SUBMISSIONS (OVERVIEW)
  */
  "tooltip_submission_open": "Ikke påbegynt",
  "tooltip_submission_writing": "Eleven jobber med teksten sin",
  "tooltip_submission_submitted": "Klar for tilbakemelding",
  "tooltip_submission_reviewed": "Eleven jobber med teksten sin",
  "tooltip_submission_complete": "Ferdig!",
  /**
  * STUDENT TEXTS (OVERVIEW)
  */
   "title_text_open": "Du har fått et nytt skriveoppdrag!",
   //for "title_text_writing" we use the dueDate helper function
   "title_text_submitted": "Læreren din ser på teksten",
   "title_text_reviewed": "Du har fått tilbakemelding",
   "title_text_completed": "Bra jobba. Du er ferdig!",
   "tooltip_text_open": "Nytt skriveoppdrag",
   "tooltip_text_writing": "Du skriver på oppdraget",
   "tooltip_text_submitted": "Læreren vurderer teksten",
   "tooltip_text_reviewed": "Du skriver på oppdraget",
   "tooltip_text_completed": "Ferdig!",
  /**
  * TEACHER REVIEW
  */
  "assignment_preview_notification": "Eleven har levert før fristen. Du kan vurdere teksten med én gang.",
  "assignment_review_notification": "Eleven jobber med tilbakemeldingen sin. Du kan når som helst vurdere teksten på nytt eller markere den som ferdig.",
  /**
   * STUDENT ASSIGNMENT (TEXT EDITOR)
   */
  "student_preview_header_button": "Skriv videre",
  "student_submitted_header_button": "Lærereren vurderer teksten",
  "student_preview_editor_banner": "Du har sendt inn teksten til vurdering før fristen har gått ut. Du kan når som helst skrive videre før fristen går ut. ", //leave trailing space
  "student_submitted_editor_banner": "Læreren din vurderer teksten. Du kan jobbe videre med teksten når læreren har sendt deg tilbakemeldingen sin.",
  /**
   * MODAL SUBMIT TEXT AFTER DEADLINE STUDENT
   */
  "modal_submit_text_text": "Etter at du har levert, kan du ikke gjøre endringer i teksten. Etter fristen blir teksten vurdert av læreren din {{teacherName}}.",
  "modal_submit_text_title": "Send inn til vurdering",
  /**
   * MODAL SUBMIT TEXT BEFORE DEADLINE STUDENT
   */
  "modal_submit_preview_text": "Etter at du har levert, kan du åpne teksten på nytt og jobbe videre frem til fristen går ut. Etter fristen blir teksten vurdert av læreren din {{teacherName}}.",
  "modal_submit_preview_title": "Send inn til vurdering",
  /**
  * MODAL OPEN TEXT PREVIEW - Elev har levert før fristen og åpner teksten for å skrive videre før fristen går ut
  */
  "modal_open_preview_text": "Når du åpner teksten, kan du jobbe videre med den. Du må levere på nytt før fristen går ut.",
  "modal_open_preview_title": "Åpne teksten på nytt",
  /**
   * MODAL PICK GREP GOALS
   */
  "learning_goal_discipline_title": "Fag",
  "learning_goal_grade_title": "Årstrinn",
  /**
   * STUDENT SEGMENTED ANALYZE
   */
   "analyze_loading_category_communication": "Sjekker kommunikasjon" ,
   "analyze_loading_category_content": "Sjekker innholdet" ,
   "analyze_loading_category_spelling": "Sjekker skrivereglene" ,
   "analyze_loading_category_language": "Sjekker språkbruken" ,
   "analyze_loading_category_structure": "Sjekker strukturen" ,
   "analyze_loading_category_punctuation": "Sjekker tegnsettingen"
};
