/**
 * Script: helpers/api.ts
 * This file contains generic functions for fetching HTTP requests.
 * Dependencies are
 *
 * humps          - humps           - Helper functions for formatting strings
 * fetch          - unfetch         - Fetch method
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/*  eslint-disable @typescript-eslint/ban-types */
import humps from "humps";
import fetch from "unfetch";

/**
 * Function for clearing local storage.
 */
export function logOut(): void {
  localStorage.clear();
}

/**
 * Helper function for checking the status of the response. If the status code is 401, log out and redirect to login page. If the response has an error, throw a new error. Else return the response.
 * @param {Response} response The HTTP-response.
 * @returns {Response | Error}
 */
function checkStatus(response: Response) {
  if (response.ok) {
    // response status in the OK range (200-299)
    return response;
  } else if (response.status === 401) {
    logOut();
    if (!response.url.includes("api/me")) {
      window.location.href = "/dataporten/auth";
    }
    return Promise.reject(Error());
  } else {
    const error = new Error(`${response.url} ${response.status.toString()} (${response.statusText})`);
    return Promise.reject(error);
  }
}

/**
 * Function for fetching data, checking the response for errors, and converting the response to json.
 * @param {string} method HTTP method.
 * @param {string} url Url.
 * @returns {Promise}
 */
function noHumpsRequest(method: string, url: string) {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json"
    }
  };

  return fetch(url, options)
    .then(response => checkStatus(response as unknown as Response))
    .then(response => response.json());
}

/**
 * Function for fetching data, checking the response for errors, converting the response to json and camelizing the keys in the json object.
 * @param {string} method HTTP method.
 * @param {string} url Url.
 * @param {any} json Body object.
 * @returns {Promise}
 */
function sendRequest(method: string, url: string, json?: unknown) {
  const options = {
    method,
    body: json && JSON.stringify(humps.decamelizeKeys(json as object)),
    headers: {
      "Content-Type": "application/json"
    }
  };

  return fetch(url, options)
    .then(response => checkStatus(response as unknown as Response))
    .then(response => response.json())
    .then(json => humps.camelizeKeys(json));
}

/**
 * Function for fetching a pdf and checking the response for errors.
 * @param {string} method HTTP method.
 * @param {string} url Url.
 * @param {any} json Body object.
 * @returns {Promise}
 */
function sendPDFRequest(method: string, url: string, json?: unknown) {
  const options = {
    method,
    body: json && JSON.stringify(humps.decamelizeKeys(json as object)),
    headers: {
      "Content-Type": "application/pdf"
    }
  };

  return fetch(url, options)
    .then(response => checkStatus(response as unknown as Response))
    .then(response => response.text());
}

/**
 * Generic functions for post, put, get, delete and fetching a PDF.
 */
const api = {
  del: (url: string): Promise<any> => sendRequest("DELETE", url),
  get: (url: string): Promise<any> => sendRequest("GET", url), // eslint-disable-line
  getNoHumps: (url: string): Promise<any> => noHumpsRequest("GET", url), // eslint-disable-line
  post: (url: string, json: unknown): Promise<any> => sendRequest("POST", url, json),
  put: (url: string, json: unknown): Promise<any> => sendRequest("PUT", url, json),
  file: (url: string, json: unknown): Promise<any> => sendPDFRequest("POST", url, json)
};

export default api;
