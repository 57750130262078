/**
 * Script: helpers/styles.ts
 * This file contains helper functions for styling
 */

/**
 * Function for converting hex-code to RGBA
 * @param {string} hex Hex-code
 * @param {number} alpha Opacity
 * @returns {string} A string of rgba color code.
 */
export function hexToRGBA(hex: string, alpha = 1): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
