/**
 * Script: components/modal-provider.tsx
 * Component for displaying modals.
 *
 * Dependencies are
 * React          - react               - React components
 * Modal          - react-modal         - React modal component
 * theme          - constants/styled    - Global styles
 * useUIStore     - store               - State management
 */
import React from "react";

import Modal from "react-modal";

import { theme } from "constants/styled";
import { useUIStore } from "store";

// Set default styles for Modal overlay and content containers
Modal.defaultStyles.content = {
  outline: "none"
};
Modal.defaultStyles.overlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, 0.6)",
  zIndex: theme.zIndex.overlay,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

// Set app element, to set aria-hidden attribute when modal is open
Modal.setAppElement(document.getElementById("root"));

const ModalProvider: React.FC = () => {
  const modal = useUIStore(state => state.modal);
  const unsetModal = useUIStore(state => state.unsetModal);

  // React.useEffect(() => {
  //   // Lock body scroll if modal is set
  //   document.body.style.overflow = props.modal ? 'hidden' : 'auto';
  // }, [props.modal]);

  const isOpen = Boolean(modal.name);

  const ModalComponent =
    modal.name && React.lazy(() => import(/* webpackChunkName: "modal-[request]" */ `../modals/${modal.name}.tsx`));

  return (
    <Modal isOpen={isOpen} onRequestClose={unsetModal}>
      {modal.name && (
        <React.Suspense fallback={null}>
          <ModalComponent {...modal.props} />
        </React.Suspense>
      )}
    </Modal>
  );
};

export default ModalProvider;
