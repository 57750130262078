/**
 * Script: store/reviewStore.ts
 * This file stores and manages states related to Skrible's user guide.
 *
 * Dependencies are
 * services                               - helpers/services    - backend-services
 * StateCreatorDev, createStore           - store/utils         - functions for creating and restoring a store
 * UserGuideState, UserGuideStateStore    - types               - interfaces
 */

import services from "helpers/services";
import { createStore, StateCreatorDev } from "store/utils";
import { UserGuideState, UserGuideStateStore } from "types";

export const initialState: UserGuideState = {
  error: null,
  pageSettings: {
    titleNb: "",
    titleNn: "",
    topSubtitleNb: "",
    topSubtitleNn: "",
    categories: [],
    bottomSubtitleNb: "",
    bottomSubtitleNn: "",
    bottomContentNb: "",
    bottomContentNn: "",
    bottomButtonTextNb: "",
    bottomButtonTextNn: ""
  },
  categories: [],
  questions: [],
  openQuestionId: null,
  searchQuery: ""
};

/**
 * User guide store
 */
const store: StateCreatorDev<UserGuideStateStore> = (set, get) => ({
  ...initialState,

  /**
   * Function for setting the selected question.
   * @param {string} id Question ID.
   */
  setOpenQuestion: (id: string) => set({ ...get(), openQuestionId: id }, false, "setIsOpen"),

  /**
   * Function for clearing the selected question.
   */
  clearOpenQuestion: () => set({ ...get(), openQuestionId: null }, false, "clearOpenQuestion"),

  /**
   * Function for setting the search query.
   * @param {string} searchQuery Search query string.
   */
  setSearchQuery: (searchQuery: string) => set({ ...get(), searchQuery }, false, "setSearchQuery"),

  /**
   * Function for fetching all user guide categories.
   */
  fetchCategories: async () => {
    try {
      const categories = await services.guide.getAllCategories();

      set({ ...get(), categories, error: null }, false, "fetchCategories");
    } catch (error) {
      set({ ...get(), error, categories: [] }, false, "fetchCategories - error");
    }
  },

  /**
   * Function for fetching all user guide questions.
   */
  fetchQuestions: async () => {
    try {
      const questions = await services.guide.getAllQuestions();

      set({ ...get(), questions, error: null }, false, "fetchQuestions");
    } catch (error) {
      set({ ...get(), error, questions: [] }, false, "fetchQuestions - error");
    }
  },

  /**
   * Function for fetching page settings.
   */
  fetchPageSettings: async () => {
    try {
      const pageSettings = await services.guide.getPageSettings();

      set({ ...get(), pageSettings, error: null, categories: pageSettings.categories }, false, "fetchPageSettings");
    } catch (error) {
      set(
        { ...get(), error, pageSettings: { ...initialState.pageSettings }, categories: [] },
        false,
        "fetchPageSettings - error"
      );
    }
  },

  /**
   * Function for fetching a category's questions.
   * @param {string} categoryId Category ID.
   */
  getQuestionsByCategory: async (categoryId: string) => {
    try {
      const questions = await services.guide.getQuestionsByCategory(categoryId);

      set({ ...get(), questions, error: null }, false, "getQuestionsByCategory");
    } catch (error) {
      set({ ...get(), error, questions: [] }, false, "getQuestionsByCategory - error");
    }
  }
});

export default createStore(store, "userGuide");
