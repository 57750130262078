/**
 * Script: routes/index.tsx
 * This file gathers all the routes in Skrible.
 *
 * Dependencies are
 * React, lazy, Suspense      - react                 - React components
 * FullPageSpinner            - components/spinner    - Full page spinner
 * useUserStore               - store                 - User store
 */

import React, { lazy, Suspense } from "react";

import { FullPageSpinner } from "components/spinner";
import { useUserStore } from "store";

// Lazy loading by routes to avoid one big initial chunk.
const StudentRoutes = lazy(() => import( /* webpackChunkName: "StudentRoutes" */ "routes/student-routes"));
const TeacherRoutes = lazy(() => import( /* webpackChunkName: "TeacherRoutes" */ "routes/teacher-routes"));
const HomeRoutes = lazy(() => import( /* webpackChunkName: "HomeRoutes" */ "routes/home-routes"));
const Language = lazy(() => import( /* webpackChunkName: "Language" */ "pages/language"));

const Routes: React.FC = () => {
  const isTeacher = useUserStore(state => state.isTeacher);
  const user = useUserStore(state => state.user);
  const Routes = isTeacher ? TeacherRoutes : StudentRoutes;

  return (
    // While the routes lazy-load, we display a pull page spinner.
    <Suspense fallback={<FullPageSpinner />}>
      {user && user?.language && <Routes />}
      {user && !user?.language && <Language />}
      {!user && <HomeRoutes />}
    </Suspense>
  );
};

export default Routes;
