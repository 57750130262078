/**
 * Script: components/header.tsx
 * Component for resetting page scroll after page navigation
 *
 * Dependencies are
 * React                                - react               - React component
 * useLocation                          - react-router-dom    - React routing component
 */
import React from "react";

import { useLocation } from "react-router-dom";

const ResetPageScroll: React.FC = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ResetPageScroll;
