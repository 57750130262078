/**
 * Script: store/assignmentStore.ts
 * This file stores and manages states related to an assignment
 *
 * Dependencies are
 * humps                              - humps           - String formatting
 * isDeadlineValid                    -/dates           - helper function to validate date
 * createStore, StateCreatorDev       - store/utils     - function for creating a store
 * AssignmentStateStore, AssignmentState,
   Assignment, OptionStateValue, PremadeAssignment,
   LearningGoalAssignment             - types           - interfaces
 */
import humps from "humps";

import { isDeadlineValid } from "helpers/dates";
import { createStore, StateCreatorDev } from "store/utils";
import { AssignmentStateStore, AssignmentState, Assignment, OptionStateValue, PremadeAssignment, LearningGoalAssignment, OptionState } from "types";

const defaultCriteriaState = {
  selected: false,
  params: {},
  personalized: undefined
};

const defaultToolState = {
  selected: false,
  params: {},
  personalized: undefined
};

const defaultState: AssignmentState = {
  content: undefined,
  criteria: {
    capitalLetters: { ...defaultCriteriaState },
    commas: { ...defaultCriteriaState },
    exclamationMarks: { ...defaultCriteriaState },
    fullStops: { ...defaultCriteriaState },
    longSentences: { ...defaultCriteriaState },
    paragraphs: { ...defaultCriteriaState },
    questionMarks: { ...defaultCriteriaState },
    repetition: { ...defaultCriteriaState },
    passiveVoice: { ...defaultCriteriaState },
    spaceCount: { ...defaultCriteriaState },
    spellcheck: { ...defaultCriteriaState },
    possessives: { ...defaultCriteriaState },
    ogAa: { ...defaultCriteriaState },
    terms: { ...defaultCriteriaState },
    textStructure: { ...defaultCriteriaState },
    title: { ...defaultCriteriaState },
    wordCount: { ...defaultCriteriaState }
  },
  deadline: undefined,
  grepLearningGoals: [],
  groupId: undefined,
  hasGrepGoals: true,
  id: undefined,
  learningGoals: [],
  name: undefined,
  state: undefined,
  teacherInfo: undefined,
  tools: {
    autocorrect: { ...defaultToolState },
    writingFrame: { ...defaultToolState },
  }
};

/**
 * Helper function for mapping an array of selected criterias for an assignment to an object.
 *
 * @param {Assignment | PremadeAssignment} assignment Selected assignment.
 * @return {OptionState} An object of mapped criterias.
 */
const mapCriteria = (assignment: Assignment | PremadeAssignment): OptionState => {
  const criteria: OptionState = { ...defaultState.criteria };

  if (assignment.criteria) {
    assignment.criteria.forEach(({ criteriaId, params, criteriaUser }) => {
      if (criteriaId && params) {
        const camelizedKey = humps.camelize(criteriaId);
        const criteriaObject: OptionStateValue = { selected: true, params };

        if (criteriaUser) {
          criteriaObject.personalized = criteriaUser;
        }

        criteria[camelizedKey] = criteriaObject;
      }
    });
  }

  return criteria;
};

/**
 * Helper function for mapping an array of selected tools for an assignment to an object
 *
 * @param {Assignment | PremadeAssignment} assignment Selected assignment.
 * @return {OptionState} An object of mapped tools.
 */
const mapTools = (assignment: Assignment | PremadeAssignment): OptionState => {
  const tools: OptionState = { ...defaultState.tools };

  if (assignment.tools) {
    assignment.tools.forEach(({ toolsId, params, toolsUser }) => {
      if (toolsId && params) {
        const camelizedKey = humps.camelize(toolsId);
        const toolObject: OptionStateValue = { selected: true, params };

        if (toolsUser) {
          toolObject.personalized = toolsUser;
        }

        tools[camelizedKey] = toolObject;
      }
    });
  }

  return tools;
};

/**
 * Assignment store
 */
const store: StateCreatorDev<AssignmentStateStore> = (set, get) => ({
  ...defaultState,

  /**
   * Function for resetting and setting a selected assignment to the store's assignment object.
   * @param {Assignment | PremadeAssignment} assignment Selected assignment.
   */
  initAssignment: (assignment?: Assignment | PremadeAssignment) => {
    if (!assignment) {
      set({ ...get(), ...defaultState }, false, "initDefaultAssignment");
    } else {
      const mappedCriteria = mapCriteria(assignment);
      const deadline = isDeadlineValid(assignment.deadline) ? assignment.deadline : null;
      const content = assignment.language && assignment.language === "nn" ? assignment.contentNn : assignment.content;
      const name = assignment.language && assignment.language === "nn" ? assignment.nameNn : assignment.name;
      const teacherInfo =
      assignment.language && assignment.language === "nn" ? assignment.teacherInfoNn : assignment.teacherInfoNb;
      const mappedTools = mapTools(assignment);

      const mappedAssignment: AssignmentState = {
        content,
        deadline: deadline || undefined,
        name,
        teacherInfo,
        groupId: assignment.groupId,
        id: assignment.id,
        criteria: mappedCriteria,
        learningGoals: assignment.learningGoal || [],
        state: assignment.state,
        hasGrepGoals: assignment.hasGrepGoals as boolean,
        grepLearningGoals: assignment.grepLearningGoal as LearningGoalAssignment[] || [],
        tools: mappedTools,
      };

      set({ ...get(), ...mappedAssignment }, false, "setAssignment");
    }
  },

  /**
   * Function for resetting the assignment store.
   */
  resetAssignment: () => {
    const newState = { ...defaultState };

    // reset nested criteria state
    Object.keys(newState.criteria).forEach(key => {
      newState.criteria[key] = { ...defaultCriteriaState };
    });

    Object.keys(newState.tools).forEach(key => {
      newState.tools[key] = { ...defaultToolState };
    });

    set({ ...get(), ...newState }, false, "resetAssignment");
  },

  /**
   * Function for setting the assignment's name and content.
   * @param {string} content The assignment's content.
   * @param {string} name The assignment's name.
   */
  setContent: (content, name) => {
    name = name ? name.trim() : name;
    set({ ...get(), content, name }, false, "setContent");
  },

  /**
   * Function for setting the assignment's deadline.
   * @param {string} deadline The assignment's deadline.
   */
  setDeadline: deadline => set({ ...get(), deadline }, false, "setDeadline"),

  /**
   * Function for setting the assignment's group id.
   * @param {string} groupId The assignment's group id.
   */
  setGroupId: groupId => set({ ...get(), groupId }, false, "setGroupId"),

  /**
   * Function for toggling a criteria.
   * @param {string} id Id of the criteria to be toggled.
   */
  toggleCriteria: id => {
    const { criteria } = get();
    const criteriaObject = { ...criteria[id] };

    criteriaObject.selected = !criteriaObject.selected;
    criteria[id] = criteriaObject;

    if (!criteria[id].selected) {
      criteria[id].params = {};
      criteria[id].personalized = undefined;
    }

    set({ ...get(), criteria }, false, "toggleCriteria");
  },

  /**
   * Function for toggling a tool.
   * @param {string} id Id of the tool to be toggled.
   */
  toggleTool: id => {
    const { tools } = get();
    const toolObject = { ...tools[id] };

    toolObject.selected = !toolObject.selected;
    tools[id] = toolObject;

    if (!tools[id].selected) {
      tools[id].params = {};
      tools[id].personalized = undefined;
    }
    set({ ...get(), tools }, false, "toggleTool");
  },

  /**
   * Function for setting the paramaters for a criteria.
   * @param {string} id Id of the criteria.
   * @param {[key: string]: string | string[]} params The criteria parameters.
   */
  setCriteriaParams: (id, params = {}) => {
    const { criteria } = get();
    const criteriaObject = { ...criteria[id] };

    criteriaObject.params = params;
    criteria[id] = criteriaObject;

    set({ ...get(), criteria }, false, "setCriteriaParams");
  },

  /**
   * Function for setting the paramaters for a tool.
   * @param {string} id Id of the tool.
   * @param {[key: string]: string | string[]} params The tool parameters.
   */
  setToolsParams: (id, params = {}) => {
    const { tools } = get();
    const toolObject = { ...tools[id] };

    toolObject.params = params;
    tools[id] = toolObject;

    set({ ...get(), tools }, false, "setToolsParams");
  },

  /**
   * Function for setting personalized criterias.
   * @param {string} id Id of the criteria.
   * @param {CriteriaUser[]} personalizedCriteria Array of personalized adjustments.
   */
  setPersonalizedCriteria: (id, personalizedCriteria) => {
    const { criteria } = get();
    const criteriaObject = { ...criteria[id] };

    criteriaObject.personalized = personalizedCriteria;

    // unset criteria if all personalizations are unset
    if (personalizedCriteria && personalizedCriteria.length == 0) {
      criteriaObject.selected = false;
    }

    criteria[id] = criteriaObject;

    set({ ...get(), criteria }, false, "setPersonalizedCriteria");
  },

  /**
   * Function for setting personalized tools.
   * @param {string} id Id of the tool.
   * @param {ToolUser[]} personalizedTool Array of personalized adjustments.
   */
  setPersonalizedTools: (id, personalizedTools) => {
    const { tools } = get();
    const toolsObject = { ...tools[id] };

    toolsObject.personalized = personalizedTools;

    // unset tools if all personalizations are unset
    if (personalizedTools && personalizedTools.length == 0) {
      toolsObject.selected = false;
    }

    tools[id] = toolsObject;

    set({ ...get(), tools }, false, "setPersonalizedTools");
  },

  /**
   * Function for setting the learning goals for the assignment.
   * @param {Set<string>} learningGoalsSet A set of the learning goals that should be updated.
   */
  setLearningGoals: learningGoalsSet => {
    const learningGoals = [...learningGoalsSet].map(id => ({ learningGoalId: id }));
    set({ ...get(), learningGoals }, false, "setLearningGoals");
  },

  /**
   * Function for setting the Grep learning goals for the assignment
   * @param {Set<string>} learningGoalsSet A set of the learning goals that should be updated.
   */
  setGrepLearningGoals: learningGoalSet => {
    const grepLearningGoals = [...learningGoalSet].map(id => ({ learningGoalId: id }));
    set({ ...get(), grepLearningGoals }, false, "setLearningGoals");
  },
});

export default createStore(store, "Assignment");
