/**
 * Script: pages/global-message.tsx
 * Page that displays onboarding modals or other welcoming modals when the user logs into Skrible the first time.
 *
 * Dependencies are
 * React                      - react             - React components
 * getCookie                  - helpers/cookie    - Function for getting cookie
 * services                   - helpers/service   - HTTP-services
 * useUserStore, useUIStore   - store             - State management
 * ModalStory                 - types             - Inteface
 */

import React from "react";

import { getCookie } from "helpers/cookie";
import services from "helpers/services";
import { useUIStore, useUserStore } from "store";
import { ModalStory } from "types";


const GlobalMessagePage: React.FC = () => {
  const [data, setData] = React.useState<ModalStory>(null);
  const [error, setError] = React.useState(null);

  const setModal = useUIStore(state => state.setModal);

  const user = useUserStore(state => state.user);
  const isTeacher = useUserStore(state => state.isTeacher);

  const role = isTeacher ? "teacher" : "student";

  // Check if modal has been previously showed (cookie exists)
  const hasShowedModals = getCookie(`info_modal_${data?.id}_prompted`);

  // Only show message if there is a message to show, and the
  const showMessage = user && !hasShowedModals && data && !error;

  // Get modal base on which role is logged in.
  React.useEffect(() => {
    services.messageModals
      .getSelectedStory(process.env.REACT_APP_MODAL_ENV, role)
      .then(response => setData(response))
      .catch(error => setError(error));
  }, [role]);

  React.useEffect(() => {
    if (showMessage) {
      setModal("onboarding", { data });
    }
  }, [data, setModal, showMessage]);

  return null;
};

export default GlobalMessagePage;
