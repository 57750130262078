/**
 * Script: store/utils.ts
 * This file consists of general functions for state management in Skrible.
 * There are functions for creating, persisting and restoring all of the stores in in the application.
 *
 * Dependencies are
 * create, State, PartialState, StateCreator,
    SetState, GetState, StoreApi                - zustand               - create method and interfaces for implementing state management
 * devtools                                     - zustand/middleware    -
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import create, { State, PartialState, StateCreator, SetState, GetState, StoreApi } from "zustand";
import { devtools } from "zustand/middleware";

// Use NamedSet from `zustand/middleware` to be able to pass through a name for the set action to Redux Devtools
type NamedSetState<S extends State> = (partial: PartialState<S>, replace?: boolean, name?: string) => void;
export type StateCreatorDev<S extends State> = (set: NamedSetState<S>, get: GetState<S>, api: StoreApi<S>) => S;

/**
 * Helper function for storing a state in the browser's local storage.
 *
 * @param {StateCreator<S>} config store config.
 * @param {string} key storage key.
 */
const persist =
  <S extends State>(config: StateCreator<S>, key: string) =>
  (set: SetState<S>, get: GetState<S>, api: StoreApi<S>) =>
    config(
      args => {
        set(args);
        window.localStorage.setItem(key, JSON.stringify(get()));
      },
      get,
      api
    );

/**
 * Function for restoring a state from local storage.
 *
 * @param {string} key Key for the state that is stored in the browser's local storage.
 * @return {any} If the store key exists, a JSON-string of the state is returned. Else nothing is returned.
 */
export const restore = (key: string) => {
  const storeKey = localStorage.getItem(key);

  if (storeKey) {
    return JSON.parse(storeKey);
  }
};

/**
 * Function for removing a key and its object from the local storage.
 * @param {string} key Storage key.
 */
export const clearStorage = (key: string) => {
  localStorage.removeItem(key);
};

/**
 * Function for creating a store.
 *
 * @param {StateCreator<T>} config Store config.
 * @param {string} prefix The store's prefix or name.
 * @param {string} storageKey Optional storage key. For storing the state in the browser's local storage.
 * @return {UseBoundStore<T, StoreApi<T>>}
 */
export const createStore = <T extends State>(config: StateCreator<T>, prefix: string, storageKey?: string) => {
  if (storageKey) return create(persist(devtools(config, prefix), storageKey));
  else return create(devtools(config, prefix));
};
