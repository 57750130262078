/**
 * Script: store/globalStore.ts
 * This file stores and manages states related to Skrible's global settings
 *
 * Dependencies are
 * isEqual                                  - react-fast-compare  - Function for comparing objects.
 * services                                 - helpers/services    - backend-services
 * StateCreatorDev, createStore, restore    - store/utils         - functions for creating and restoring a store
 * GlobalStateStore, GlobalState,
 * LearningGoal, WritingFrame               - types               - interfaces
 */
import isEqual from "react-fast-compare";

import services from "helpers/services";
import { GlobalStateStore, GlobalState, LearningGoal, WritingFrame } from "types";

import { StateCreatorDev, createStore, restore, clearStorage } from "./utils";

const STORAGE_KEY = "persistedState";

export const defaultState: GlobalState = {
  error: null,
  adminError: null,
  assignments: [],
  isLoadingAssignments: true,
  learningGoals: [],
  learningGoalCategories: [],
  premadeAssignments: [],
  subjects: [],
  text: {
    assignmentId: null,
    content: null,
    title: null,
    wordsToIgnore: []
  },
  writingFrames: [],
  writingFrameFilters: {
    searchQuery: "",
    sortAlphabeticallyAscending: true,
    selectedLanguage: "",
    selectedSubject: "",
    selectedOwner: "",
  },
  assignmentFilter: {
    selectedWindow: "month"
  }
};

// if the keys in Storage keys differ from default state, then we need to update initial state
const storedKeys = Object.keys(restore(STORAGE_KEY) || {});
const initialKeys = Object.keys(defaultState);
if (!isEqual(storedKeys, initialKeys)) {
  clearStorage(STORAGE_KEY);
}

const initialState = restore(STORAGE_KEY) || defaultState;

/**
 * Global store
 */
const store: StateCreatorDev<GlobalStateStore> = (set, get) => ({
  ...initialState,

  /**
   * A function for setting a global error in Skrible.
   * @param {Error} error The error that is returned from backend.
   */
  setError: (error: Error) => set({ ...get(), error }, false, "setError"),

  /**
   * A function for setting a global admin error in Skrible.
   * @param {Error} error The error that is returned from backend.
   */
  setAdminError: (adminError: Error) => set({ ...get(), adminError }, false, "setAdminError"),

  /**
   * A function for clearing both error and adminError.
   */
  clearError: () => set({ ...get(), error: null, adminError: null }, false, "clearError"),

  /**
   * A function for fetching all assignments visible to the user.
   * @param {string} window One of month/quart/half/year/all to select the time window to fetch.
   */
  fetchAssignments: async (window?: string) => {
    try {
      if (window){
        const {list: assignments} = await services.assignments.getAllWindow(window)
        set({ ...get(), error: null, assignments, assignmentFilter: {selectedWindow: window} }, false, "fetchAssignments");
      }else{
        const {list: assignments} = await services.assignments.getAll();
        set({ ...get(), error: null, assignments, assignmentFilter: {selectedWindow: "month"} }, false, "fetchAssignments");
      }
    } catch (error) {
      set({ ...get(), error, assignments: [] }, false, "fetchAssignments - error");
    } finally {
      set({ ...get(), isLoadingAssignments: false }, false, "setLoading - false");
    }
  },

  /**
   * A function for updating an assignment's state.
   * @param {string} assignmentId The assignment's id.
   * @param {string} state The assignment's new state.
   */
  updateAssignmentState: (assignmentId, state) => {
    const assignments = [...get().assignments];

    const assignment = assignments.find(a => a.id === assignmentId);
    assignment.state = state;

    set({ ...get(), assignments }, false, "updateAssignmentState");
  },

  /**
   * A function for updating a text in an assignment.
   * @param {string} assignmentId The assignment's id.
   * @param {Text} text The new text.
   */
  updateTextInAssignment: (assignmentId, text) => {
    const assignments = [...get().assignments];

    const assignment = assignments.find(a => a.id === assignmentId);
    const textIndex = assignment?.texts.findIndex(t => t.id === text.id);

    if (textIndex >= 0) {
      assignment.texts[textIndex] = text;
    }

    set({ ...get(), assignments }, false, "updateTextInAssignment");
  },

  /**
   * A function for setting all of texts in an assignment to the status "completed".
   * @param {string} assignmentId The assignment's id.
   */
  completeAllTextsInAssignment: assignmentId => {
    const assignments = [...get().assignments];
    const assignment = assignments.find(a => a.id === assignmentId);

    for (const text of assignment.texts) {
      text.state = "completed";
    }

    set({ ...get(), assignments }, false, "completeAllTextsInAssignment");
  },

  /**
   * A function for setting all of the texts in an assignment to the status "open".
   * @param {string} assignmentId The assignment's id.
   */
  openAllTextsInAssignment: assignmentId => {
    const assignments = [...get().assignments];
    const assignment = assignments.find(a => a.id === assignmentId);

    for (const text of assignment.texts) {
      text.state = "open";
    }

    set({ ...get(), assignments }, false, "openAllTextsInAssignment");
  },

  /**
   * A function for getting all of Skrible's premade assignments.
   */
  fetchPremadeAssignments: async () => {
    try {
      const premadeAssignments = await services.premade.getAll();

      set({ ...get(), adminError: null, premadeAssignments }, false, "fetchPremadeAssignments");
    } catch (error) {
      set({ ...get(), premadeAssignments: [], adminError: error }, false, "fetchPremadeAssignments - error");
    }
  },

  /**
   * A function for setting the text.
   * @param {Text} text The text that will be set.
   */
  setText: text => set({ ...get(), text: { ...get().text, ...text } }, false, "setText"),

  /**
   * A function for resetting this store's text back to the default value.
   */
  resetText: () => set({ ...get(), text: { ...defaultState.text } }, false, "resetText"),

  /**
   * A function for fetching all learning goals.
   */
  fetchLearningGoals: async () => {
    try {
      const { list: learningGoals } = await services.learningGoals.getAll();
      const learningGoalCategories: string[] = [];

      learningGoals.forEach((goal: LearningGoal) => {
        if (goal.discipline.trim() == "") {
          console.error(`Learning goal (id: ${goal.id}) is missing discipline`);
          return;
        }

        if (!learningGoalCategories.includes(goal.discipline)) {
          learningGoalCategories.push(goal.discipline);
        }
      });

      set({ ...get(), learningGoals, learningGoalCategories, error: null }, false, "fetchLearningGoals");
    } catch (error) {
      set({ ...get(), error, learningGoals: [], learningGoalCategories: [] }, false, "fetchLearningGoals - error");
    }
  },

  /**
   * A function for fetching all writing frames, both the premade and the selfmade ones.
   */
  fetchWritingFrames: async () => {
    try {
      const premadeWritingFrames = await services.premadeWritingFrames.getAll();
      const { list: writingFrames } = await services.writingFrames.getAll();
      set({ ...get(), error: null, writingFrames: [...writingFrames, ...premadeWritingFrames] }, false, "fetchWritingFrames");
    } catch (error) {
      set({ ...get(), error, writingFrames: [] }, false, "fetchWritingFrames - error");
    }
  },

  /**
   * A function for fetching a writing frame.
   * @param {string} writingFrameId The id of the writing frame.
   */
  fetchWritingFrame: async (writingFrameId: string) => {
    try {
      const isWritingFrameCached = get().writingFrames.find(writingFrame => writingFrame.id === writingFrameId);

      if (writingFrameId && !isWritingFrameCached) {
        const premadeWritingFrame = await services.premadeWritingFrames.get(writingFrameId);

        if (Object.keys(premadeWritingFrame).filter(v => v !== "cache").length !== 0) {
          set({ ...get(), error: null, writingFrames: [...get().writingFrames, premadeWritingFrame] }, false, "fetchWritingFrame");
        } else {
          const writingFrame = await services.writingFrames.get(writingFrameId);
          set({ ...get(), error: null, writingFrames: [...get().writingFrames, writingFrame] }, false, "fetchWritingFrame");
        }

      }
    } catch (error) {
      set({ ...get(), error, writingFrames: [] }, false, "fetchWritingFrame - error");
    }
  },

  /**
   * A function for adding a writing frame to the global store.
   * @param {WritingFrame} newWritingFrame The new writing frame that should be added.
   */
  addWritingFrame: (newWritingFrame: WritingFrame) => {
    set({ ...get(), writingFrames: [...get().writingFrames, newWritingFrame] }, false, "addWritingFrame");
  },

  /**
   * A function for removing a writing frame from the global store.
   * @param {string} writingFrameId The id of the writing frame that should be removed.
   */
  removeWritingFrame: (writingFrameId: string) => {
    const deleteIndex = get().writingFrames.findIndex(writingFrame => writingFrame.id === writingFrameId);
    const updatedWritingFrames = get().writingFrames;
    updatedWritingFrames.splice(deleteIndex, 1);
    set({ ...get(), writingFrames: [...updatedWritingFrames] }, false, "removeWritingFrame");
  },

  /**
   * A function for updating a writing frame.
   * @param {WritingFrame} updatedWritingFrame The writing frame that should be updated.
   */
  updateWritingFrame: (updatedWritingFrame: WritingFrame) => {
    const updateIndex = get().writingFrames.findIndex(writingFrame => writingFrame.id === updatedWritingFrame.id);
    const updatedWritingFrames = get().writingFrames;
    updatedWritingFrames.splice(updateIndex, 1, updatedWritingFrame);
    set({ ...get(), writingFrames: [...updatedWritingFrames] }, false, "fetchWupdateWritingFrameritingFrame");
  },

  /**
   * A funciton for setting the writing frame filters.
   * @param {WritingFrameFilters} writingFrameFilters The values of the writing frame filters.
   */
  setWritingFrameFilters: writingFrameFilters => set({ ...get(), writingFrameFilters }, false, "setWritingFrameFilters"),

});

export default createStore(store, "Global", STORAGE_KEY);
