/**
 * Script: helpers/cookie.ts
 * This file contains helper functions for handling cookies.
 *
 * Dependencies are
 * Cookies           - universal-cookie               - React component
 */
import Cookies from "universal-cookie";

const cookies = new Cookies();

const defaultTTL = 60 * 60 * 24 * 365 * 2; // 2 years

/**
 * Sets and return a cookie with name and max time.
 * @param {string} name Name of the cookie.
 * @param {number} ttl How many seconds the cookie should live.
 */
export const setMessageHasLoadedCookie = (name: string, ttl: number = defaultTTL): void => {
  return cookies.set(name, true, {
    maxAge: ttl
  });
};

/**
 * Gets cookie.
 * @param {string} name Name of the cookie.
 * @returns The cookie.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCookie = (name: string): any => {
  return cookies.get(name);
};
