/**
 * Script: app.tsx
 * The main component in the Skrible application
 *
 * Dependencies are
 * Global, ThemeProvider        - @emotion/react                    - components from the styling library emotion
 * BrowserRouter                - react-router-dom                  - routing components
 * ModalProvider                - components/modal-provider         - component for providing modals
 * ResetPageScroll              - components/reset-page-scroll      - component for resetting page scroll after page navigation
 * FullPageSpinner              - components/spinner                - loader component
 * globalStyles, theme          - constants/styled                  - global styles and themes
 * GlobalMessage                - pages/global-message              - component for displaying global messages and welcome modals
 * Routes                       - routes                            - the application's routes
 * useGlobalStore, useUserStore - store                             - global store for storing states
 */

import React from "react";

import { Global, ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";

import ModalProvider from "components/modal-provider";
import ResetPageScroll from "components/reset-page-scroll";
import { FullPageSpinner } from "components/spinner";
import { globalStyles, theme } from "constants/styled";
import GlobalMessage from "pages/global-message";
import Routes from "routes";
import { useGlobalStore, useUserStore } from "store";

const App: React.FC = () => {
  const isLoadingUser = useUserStore(state => state.isLoadingUser);
  const setUser = useUserStore(state => state.setUser);
  const clearError = useGlobalStore(state => state.clearError);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { clearError() }, []);
  React.useEffect(() => setUser(), [setUser]);

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <BrowserRouter>
        {isLoadingUser ? (
          <FullPageSpinner />
        ) : (
          <>
            <Routes />
            <GlobalMessage />
            <ResetPageScroll />
            <ModalProvider />
          </>
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
