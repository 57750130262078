/**
 * Script: components/spinner.tsx
 * Spinners/loaders.
 *
 * Dependencies are
 * React                                - react                   - React components
 * styled, theme                        - constants/styled        - Global styles
 * hexToRGBA                            - helpers/styles          - Function for converting hex-code to rgba
 */
import React from "react";

import styled, { theme } from "constants/styled";
import { hexToRGBA } from "helpers/styles";

interface SpinnerProps {
  borderWidth: string;
  color?: string;
  zIndex?: string;
  margin: string;
  size: string;
}

// prettier-ignore
const Spinner = styled.div<SpinnerProps>(props =>`
  height: ${props.size};
  width: ${props.size};
  z-index: ${props.zIndex};
  margin: ${props.margin};

  border: ${props.borderWidth} solid ${hexToRGBA(props.color ?  props.color : props.theme.colors.primary, 0.3)};
  border-top-color: ${props.color ? props.color : "#ffffff"};
  border-radius: 50%;

  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`);

export default Spinner;

export const FullPageSpinner: React.FC = () => <Spinner borderWidth="5px" color={theme.colors.accent} margin="40vh auto" size="100px" />;
