/**
 * Script: store/userStore.ts
 * This file stores and manages states related to user.
 *
 * Dependencies are
 * setLocale                        - helpers/locale      - function for setting the locale
 * services                         - helpers/services    - backend-services
 * StateCreatorDev, createStore     - store/utils         - functions for creating and restoring a store
 * UserState, UserStateStore        - types               - interfaces
 */

import { setLocale } from "helpers/lokalise";
import services from "helpers/services";

import { UserState, UserStateStore } from "../types";
import { createStore, StateCreatorDev } from "./utils";

export const initialState: UserState = {
  error: null,
  user: null,
  groups: null,
  isLoadingUser: true,
  isTeacher: null
};

/**
 * User store
 */
const store: StateCreatorDev<UserStateStore> = (set, get) => ({
  ...initialState,

  /**
   * Function for clearing user from store.
   */
  clearUser: () => set({ ...get(), user: null, groups: null }, false, "clearUser"),

  /**
   * A function for setting user's language.
   * @param {string} language The language code.
   */
  setLanguage: language => {
    setLocale(language);
    set({ ...get(), user: { ...get().user, language } }, false, "setLanguage");
  },

  /**
   * A function for setting the authenticated user, getting the user's groups and setting the user's language.
   */
  setUser: async () => {
    try {
      const { isTeacher, user } = await services.auth.getMe();
      const groups = await services.auth.getGroups();

      if (user.language) {
        setLocale(user.language);
      }

      set({ ...get(), groups, isTeacher, user, error: null }, false, "setUser");
    } catch (error) {
      console.warn("Please log in to use the app");
      set({ ...get(), ...initialState, error }, false, "setUser - error");
    } finally {
      set({ ...get(), isLoadingUser: false }, false, "setUser - finally");
    }
  }
});

export default createStore(store, "User");
