/**
 * Script: store/reviewStore.ts
 * This file stores and manages states related to Skrible's user interface.
 *
 * Dependencies are
 * StateCreatorDev, createStore         - store/utils         - functions for creating and restoring a store
 * UIState, UIStateStore, ModalProps    - types               - interfaces
 */

import { UIState, UIStateStore, ModalProps } from "../types";
import { createStore, StateCreatorDev } from "./utils";

export const initialState: UIState = {
  selectedAssignmentTab: 0,
  selectedTextTab: 0,
  selectedLanguage: "nb",
  isAutoSaved: false,
  isAutoSaving: false,
  autoSavingError: null,
  isEvaluationMode: false,
  isFocusMode: false,
  showWritingFrame: true,
  isAssignmentEditorInitialized: false,
  selectedEvaluationCriteria: undefined,
  modal: {
    name: undefined,
    props: undefined
  }
};

/**
 * User interface store.
 */
const store: StateCreatorDev<UIStateStore> = (set, get) => {
  return {
    ...initialState,

    /**
     * Function for setting selected language.
     * @param {string} language Language code for the selected language.
     */
    setLanguage: (language: string) => set({ ...get(), selectedLanguage: language }, false, "setLanguage"),

    /**
     * Function for setting the selected tab index for tabs in Skrible.
     * @param {number} index Tab index.
     */
    setSelectedAssignmentTab: (index: number) => set({ ...get(), selectedAssignmentTab: index }, false, "setSelectedAssignmentTab"),

    /**
     * Function for setting the selected tab index for the student assignment tabs.
     * @param {number} index Tab index.
     */
    setSelectedTextTab: (index: number) => set({ ...get(), selectedTextTab: index }, false, "setSelectedTextTab"),

    /**
     * Function for setting and opening a modal.
     * @param {string} name The modal's file-name. The modal has to exist in the modals-folder.
     * @param {ModalProps} props Optional modal props.
     */
    setModal: (name: string, props?: ModalProps) => set({ ...get(), modal: { name, props } }, false, "setModal"),

    /**
     * Function for unsetting and closing a modal.
     */
    unsetModal: () => set({ ...get(), modal: { ...initialState.modal } }, false, "unsetModal"),

    /**
     * Function for toggling evaluation mode.
     */
    toggleEvaluationMode: () =>
      set({ ...get(), isEvaluationMode: !get().isEvaluationMode }, false, "toggleEvaluationMode"),

    /**
     * Function for unsetting evaluation mode.
     */
    unsetEvaluationMode: () => set({ ...get(), isEvaluationMode: false }, false, "unsetEvaluationMode"),

    /**
     * Function for toggling focus mode in the student's editor.
     */
    toggleFocusMode: () => set({ ...get(), isFocusMode: !get().isFocusMode }, false, "toggleFocusMode"),

    /**
     * Function for toggling the visibility of a writing frame in the student's editor.
     */
    toggleWritingFrame: () => set({ ...get(), showWritingFrame: !get().showWritingFrame }, false, "toggleWritingFrame"),

    /**
     * Function for setting the selected evaluation criteria.
     * @param {string} type The criteria type.
     */
    selectEvaluationCriteria: type =>
      set({ ...get(), selectedEvaluationCriteria: type }, false, "selectEvaluationCriteria"),

    /**
     * Function for setting the autosaving error.
     * @param {Error} error HTTP-error that is thrown from backend after autosaving.
     */
    setAutoSavingError: (error: Error) => set({ ...get(), autoSavingError: error }, false, "setAutoSavingError"),

    /**
     * Function for clearing the autosaving error.
     */
    clearAutoSavingError: () => set({ ...get(), autoSavingError: null }, false, "clearAutoSavingError"),

    /**
     * Function for setting the autosaving status in Skrible.
     * @param {boolean} b Whether Skrible is currently autosaving or not.
     */
    setIsAutoSaving: (b: boolean) => set({ ...get(), isAutoSaving: b }, false, "setIsAutoSaving"),

    /**
     * Function for setting the status for whether the assignment editor has been initialized or not.
     * @param {boolean} b
     */
    setIsAssignmentEditorInitialized: (b: boolean) => set({ ...get(), isAssignmentEditorInitialized: b }, false, "setIsAssignmentEditorInitialized")
  };
};

export default createStore(store, "UI");
