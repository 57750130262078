/**
 * Script: locale/nn.ts
 * Translation file for Norwegian Nynorsk.
 */
export default {
  "a11y_assignments_tabs": "Forskjellige typar skriveoppdrag",
  "a11y_label_assignment_menu_button": "Oppdragsmeny",
  "a11y_label_button_back": "Tilbake",
  "a11y_label_button_close": "Lukk",
  "a11y_label_button_more": "Opne",
  "a11y_label_comment_menu_button": "Kommentarmeny",
  "a11y_label_editor": "Redigeringsverktøy",
  "a11y_label_feedback_button": "Gi tilbakemelding",
  "a11y_label_filter_learning_goals": "Søk etter kompetansemål",
  "a11y_label_ignore_button": "Ignorer",
  "a11y_label_print_menu_button": "Skriv ut-meny",
  "a11y_label_scroll_to_text_content": "Gå til avsnittet 'Kva hjelper Skrible med?'",
  "a11y_label_search": "Søk",
  "a11y_label_sort_alphabetically": "Sorter alfabetisk",
  "a11y_label_toolbar_link_input": "Lim inn lenkja",
  "a11y_label_user_menu_toggle": "Opne",
  "a11y_label_word_count_max": "Minimum tal på ord",
  "a11y_label_word_count_min": "Maksimum tal på ord",
  "a11y_skip_to_assignment_editor": "Gå til redigeringsverktøyet",
  "a11y_skip_to_assignment_evaluation_criteria": "Gå til vurderingskriteria",
  "a11y_skip_to_assignment_settings": "Gå til innstillingar",
  "a11y_skip_to_assignment_tabs": "Gå til fanene med forskjellige typar skriveoppdrag",
  "a11y_skip_to_assignment_tools": "Gå til verktøya",
  "a11y_skip_to_create_assignment": "Gå til Nytt skriveoppdrag-knappen",
  "a11y_skip_to_main_content": "Gå til hovuddelen av sida",
  "a11y_skip_to_start_writing": "Gå til Begynn å skrive-knappen",
  "a11y_skip_to_student_text_review": "Sjå læraren si vurdering av teksten",
  "a11y_skip_to_teacher_info": "Gå til lærarinformasjonen",
  "a11y_skip_to_teacher_text_review": "Vurder teksten",
  "a11y_skip_to_text_evaluation": "Gå til vurdering av teksten",
  "a11y_skip_to_text_tabs": "Gå til beskrivinga av skriveoppdraget",
  "a11y_skip_to_writing_frame_filter": "Gå til søkefeltet til skriverammene",
  "a11y_student_assignment_tabs": "Teksten din",
  "a11y_teacher_assignment_tabs": "Innleveringar",
  "assignment_description_placeholder": "Beskriv skriveoppdraget til eleven her ...",
  "assignment_due_date": "Fristen er {{date}} kl. {{time}}.",
  "assignment_due_days": {
    "one": "Fristen er om {{count}} dag.",
    "other": "Fristen er om {{count}} dagar."
  },
  "assignment_due_hours": "Fristen er om 4 timar",
  "assignment_due_today": "Fristen er i dag.",
  "assignment_due_weekday": "Fristen er på fredag.",
  "assignment_no_class": "Du har ikkje valt nokon klasse",
  "assignment_no_deadline": "Du har ikkje valt nokon frist",
  "assignment_no_evaluation_criteria": "Du har ikkje valt noko vurderingskriterium",
  "assignment_no_learning_goals": "Du har ikkje valt noko kompetansemål",
  "assignment_no_tools": "Du har ikkje valt noko verktøy",
  "assignment_title_placeholder": "Gi skriveoppdraget ei overskrift",
  "assignments_not_available": "Du har ingen skriveoppdrag",
  "autosave_text_error_1": "Skrible mista kontakten. Du har ulagra endringar.",
  "autosave_text_error_2": "Alt blir lagra når Skrible får kontakt igjen.",
  "autosave_text_saving": "Lagrar...",
  "autosave_text_success": "Alle endringane er lagra",
  "completed_texts_not_available": "Du har kkje nokre ferdige skriveoppdrag",
  "criteria_capital_letter_student_description": "Du skal bruke stor og liten forbokstav riktig.",
  "criteria_capital_letter_subtitle": "Bruk stor forbokstav",
  "criteria_capital_letter_teacher_description": "Eleven skal kunne bruke stor og liten forbokstav riktig.",
  "criteria_capital_letter_text": "Sjekk om dette ordet skal ha stor forbokstav:",
  "criteria_capital_letter_title": "Stor og liten forbokstav",
  "criteria_comma_student_description": "Du skal følgje kommareglane og setje komma framfor «men».",
  "criteria_comma_subtitle": "Set komma framfor men",
  "criteria_comma_teacher_description": "Eleven skal følgje kommareglane og setje komma framfor «men».",
  "criteria_comma_text": "Det ser ut som du manglar eit komma her:",
  "criteria_comma_title": "Komma",
  "criteria_exclamation_mark_ratio_subtitle": "Fjern nokre utropsteikn",
  "criteria_exclamation_mark_ratio_text": "Det ser ut som du har for mange utropsteikn i teksten.",
  "criteria_exclamation_mark_student_description": "Du skal bruke utropsteikn når det passar, men ikkje overdrive talet på utropsteikn.",
  "criteria_exclamation_mark_subtitle": "Fjern ekstra utropsteikn",
  "criteria_exclamation_mark_teacher_description": "Eleven skal bruke utropsteikn når det passar, men ikkje overdrive talet på utropsteikn.",
  "criteria_exclamation_mark_text": "Det ser ut som du har for mange utropsteikn her:",
  "criteria_exclamation_mark_title": "Utropsteikn",
  "criteria_full_stop_student_description": "Du skal skrive titlar utan punktum.",
  "criteria_full_stop_subtitle": "Fjern punktum i overskrifta",
  "criteria_full_stop_teacher_description": "Eleven skal skrive titlar utan punktum.",
  "criteria_full_stop_text": "Overskrifter skal ikkje ha punktum:",
  "criteria_full_stop_title": "Punktum",
  "criteria_long_sentence_student_description": "Du skal skrive setningar som ikkje er for lange.",
  "criteria_long_sentence_subtitle": "Kan du korte ned setninga, eller lage to setningar?",
  "criteria_long_sentence_teacher_description": "Eleven skal skrive setningar som ikkje er for lange.",
  "criteria_long_sentence_text": "Denne setninga er lang. Setninga kan vere vanskeleg å lese.",
  "criteria_long_sentence_title": "Setningslengde",
  "criteria_paragraph_student_description": "Du skal ha avsnitt i teksten din.",
  "criteria_paragraph_subtitle": "Kan du korte ned avsnittet, eller dele det i to avsnitt?",
  "criteria_paragraph_teacher_description": "Eleven skal ha avsnitt i teksten sin.",
  "criteria_paragraph_text": "Dette avsnittet er langt. Avsnittet kan vere vanskeleg å lese.",
  "criteria_paragraph_text_subtitle": "Kan du dele teksten i avsnitt?",
  "criteria_paragraph_text_text": "Teksten din manglar avsnitt. Finn du stadar der du kan dele opp teksten din?",
  "criteria_paragraph_title": "Avsnitt",
  "criteria_question_mark_student_description": "Du skal bruke spørsmålsteikn når du skriv eit spørsmål.",
  "criteria_question_mark_subtitle": "Legg til spørsmålsteikn",
  "criteria_question_mark_teacher_description": "Eleven skal bruke spørsmålsteikn når du skriv eit spørsmål.",
  "criteria_question_mark_text": "Denne setninga ser ut som eit spørsmål, men manglar spørsmålsteikn.",
  "criteria_question_mark_title": "Spørsmålsteikn",
  "criteria_repetition_student_description": "Du skal variere språket ditt.",
  "criteria_repetition_subtitle": "Kan du byte ut dette ordet nokre stader i teksten?",
  "criteria_repetition_teacher_description": "Eleven skal variere språket sitt.",
  "criteria_repetition_text": "Dette ordet bruker du mange gonger.",
  "criteria_repetition_title": "Variert språk",
  "criteria_passive_voice_student_description": "Skrible seier ifrå når du skriv ei passivsetning. Du vurderer sjølv om det er best å skrive om setninga.",
  "criteria_passive_voice_subtitle": "Vurder å skrive om",
  "criteria_passive_voice_teacher_description": "Skrible seier ifrå når eleven skriv ei passivsetning. Eleven skal vurdere om det er best å skrive om setninga.",
  "criteria_passive_voice_text": "Dette ser ut som ei passivsetning. Den kan vere vanskeleg å lese. Viss det er viktig å få fram kven som utfører handlinga, kan du skrive om.",
  "criteria_passive_voice_title": "Passivar",
  "criteria_space_count_student_description": "Du skal ha berre eitt mellomrom mellom ord.",
  "criteria_space_count_subtitle": "Fjern ekstra mellomrom",
  "criteria_space_count_teacher_description": "Eleven skal ha berre eitt mellomrom mellom ord.",
  "criteria_space_count_text": "Det ser ut som du bruker for mange mellomrom her:",
  "criteria_space_count_title": "Mellomrom mellom ord",
  "criteria_spellcheck_student_description": "Du skal bruke reglane for skriftleg bokmål og nynorsk.",
  "criteria_spellcheck_subtitle": "Sjå over stavemåten",
  "criteria_spellcheck_teacher_description": "Eleven skal følgje reglane for skriftleg bokmål og nynorsk.",
  "criteria_spellcheck_text": "Det ser ut som dette ordet er stava feil. Her er nokre forslag som kan hjelpe deg:",
  "criteria_spellcheck_text_no_suggestions": "Vi har ikkje dette ordet i ordlista. Du kan ignorere det ved å trykke på krysset nede i venstre hjørne.",
  "criteria_spellcheck_title": "Rettskriving",
  "criteria_possessives_student_description": "Du skal bøye eigedomsord (for eksempel «min», «mitt», «din», «sin») riktig.",
  "criteria_possessives_subtitle": "Bøy ordet, så det passar",
  "criteria_possessives_teacher_description": "Eleven skal bøye eigedomsord (for eksempel «min», «mitt», «din», «sin») riktig.",
  "criteria_possessives_text": "Det ser ut som dette ordet er bøygd feil.",
  "criteria_possessives_title": "Eigedomsord",
  "criteria_og_aa_student_description": "Du skal bruke «og» eller «å» riktig.",
  "criteria_og_aa_subtitle": "Sjå over stavemåten",
  "criteria_og_aa_teacher_description": "Eleven skal bruke «og» eller «å» riktig.",
  "criteria_og_aa_text": "Det ser ut som dette ordet er brukt feil. Her er eit forslag:",
  "criteria_og_aa_title": "«Å» eller «og»",
  "criteria_structure_student_description": "Du skal dele teksten inn i innleiing, hovuddel og avslutning.",
  "criteria_structure_teacher_description": "Eleven skal dele teksten inn i innleiing, hovuddel og avslutning.",
  "criteria_structure_title": "Tekstoppbygging",
  "criteria_terms_student_description": "Du skal nemne utvalde ord i teksten din.",
  "criteria_terms_student_goals_description": "Du skal nemne desse orda i teksten din:",
  "criteria_terms_subtitle": {
    "one": "{{count}}/{{numberRequired}} er nemnt.",
    "other": "{{count}}/{{numberRequired}} er nemnde."
  },
  "criteria_terms_success_text": "Bra jobba! Du har nemnt alle orda.",
  "criteria_terms_teacher_description": "Eleven skal nemne utvalde ord i teksten sin. Hugs å bruke oppslagsforma av ordet – det ubøygde ordet. Trykk enter for å leggje til eit ord.",
  "criteria_terms_teacher_goals_description": "Eleven skal nemne desse orda i teksten sin:",
  "criteria_terms_text": "Du har nemnt {{numberUsed}} av {{numberRequired}} ord.",
  "criteria_terms_title": "Ord du skal bruke i teksten",
  "criteria_text_structure_text": "Har du delt opp teksten din i innleiing, hovuddel og avslutning?",
  "criteria_text_structure_title": "Tekstoppbygging",
  "criteria_title_student_description": "Du skal skrive ei overskrift som vekkjer interesse.",
  "criteria_title_teacher_description": "Eleven skal skrive ei overskrift som vekkjer interesse.",
  "criteria_title_text": "Har du skrive ei overskrift som gjer lesaren interessert?",
  "criteria_title_title": "Overskrift",
  "criteria_type_communication_label": "Kommunikasjon",
  "criteria_type_communication_text_student": "Gir overskrifta nyttig informasjon om kva teksten handlar om? Får lesaren lyst til å lese vidare? Skrible minner deg på å sjekke om overskrifta er interessant. Læraren din vurderer i tillegg kor godt teksten din er tilpassa lesaren og sjangeren.",
  "criteria_type_communication_text_teacher": "Skrible minner eleven på å sjekke om overskrifta er interessant.\n\nI tillegg kan du vurdere kor godt teksten er tilpassa lesaren og sjangeren:\n- Kommuniserer teksten med lesaren på ein relevant måte?\n- Har eleven valt ein skrivarposisjon som passar til sjangeren? \n- Har teksten ei overskrift som vekkjer interesse og orienterer lesaren på ein god måte?",
  "criteria_type_content_label": "Innhald",
  "criteria_type_content_text_student": "Skrible vurderer om teksten din inneheld riktig tal på ord. Har læraren din bede deg bruke visse ord i teksten, sjekkar Skrible om dei er med.",
  "criteria_type_content_text_teacher": "Skrible vurderer om teksten inneheld riktig tal på ord. Har du bede eleven bruke visse ord i teksten, sjekkar Skrible om dei er med.\n\nI tillegg kan du vurdere dette: \n- Er innhaldet relevant og utdjupa?\n- Korleis framstiller eleven tankar, meiningar, synspunkt eller kunnskapsområde?",
  "criteria_type_intro_link_student": "forventningsnormene til Skrivesenteret",
  "criteria_type_intro_link_teacher": "forventningsnormene til Skrivesenteret",
  "criteria_type_intro_student": "Vurderingskriteria og beskrivingane er inspirerte av {{link}}.",
  "criteria_type_intro_teacher": "Vurderingskriteria og beskrivingane er inspirerte av {{link}}.",
  "criteria_type_language_label": "Språkbruk",
  "criteria_type_language_text_student": "Skrible gir deg tips dersom eitt eller fleire ord blir brukte for mykje i teksten. I tillegg seier Skrible ifrå dersom setningane du skriv, er for lange. Ei setning på meir enn 20 ord blir rekna som lang.\n\nSkrible seier også ifrå om du skriv passive setningar. Du vurderer sjøl om det er best å skrive om setningane, viss det er viktig å få fram kven som utfører handlinga.",
  "criteria_type_language_text_teacher": "Skrible gir eleven tips dersom eitt eller fleire ord blir brukte for mykje i teksten. I tillegg seier Skrible ifrå dersom setningane blir for lange.\n\nSkrible seier også ifrå om eleven skriv passive setningar. Eleven vurderer sjøl om det er best å skrive om setningane, hvis det er viktig å få fram kven som utfører handlinga.\n\nDu kan i tillegg vurdere dette: \n- Kor variert, relevant og presist er ordvalet, den språklege stilen og strukturen på setningane? Bruker eleven fagomgrep? Varierer eleven setningsstrukturen, for eksempel ved å bruke leddsetningar?\n- Bruker eleven varierte språklege verkemiddel?",
  "criteria_type_punctuation_label": "Teiknsetjing",
  "criteria_type_punctuation_text_student": "Skrible hjelper deg med riktig teiknsetjing og seier ifrå viss\n- eit spørsmål manglar spørsmålsteikn\n- ei overskrift har punktum\n- komma framfor «men» manglar\n- teksten inneheld for mange utropsteikn",
  "criteria_type_punctuation_text_teacher": "Skrible hjelper eleven med riktig teiknsetjing og seier ifrå viss\n- eit spørsmål manglar spørsmålsteikn\n- ei overskrift har punktum\n- komma framfor «men» manglar\n- teksten inneheld for mange utropsteikn \n\nI tillegg kan du vurdere dette: \n- Meistrar eleven bruk av punktum, utropsteikn, spørsmålsteikn, kolon, parentes og bindestrek?\n- Er komma brukt ved oppramsing, mellom heilsetningar og etter leddsetningar?\n- Viss direkte tale er brukt: Er det markert?",
  "criteria_type_spelling_label": "Skrivereglar",
  "criteria_type_spelling_text_student": "Skrible rettleier deg mens du skriv. Du får beskjed viss eit ord er stava feil. Du får også beskjed viss eit særnamn (for eksempel namnet på ein person, eit land eller eit firma) manglar stor forbokstav. Skrible kan sjekke om du har for mange mellomrom mellom ord.\n\nSkrible sjekkar om du bøyer eigedomsord riktig i ei setning. Eigedomsord er ord som for eksempel «min», «mitt», «din», «våre». Skrible kan gi beskjed om du følgjer reglane for «og/å».",
  "criteria_type_spelling_text_teacher": "Skrible rettleier eleven i skrivinga og gir beskjed viss eit ord er stava feil. Eleven får også beskjed viss eit særnamn (for eksempel namnet på ein person, eit land eller eit firma) manglar stor forbokstav, eller om det er for mange mellomrom mellom ord.\n\nSkrible vurderer om eleven bøyer eigedomsord riktig i ei setning (for eksempel «min», «mitt», «din», «våre»).\n\nSkrible sjekkar også om eleven følgjer reglane for «og/å».\n\nI tillegg kan du vurdere dette: \n- Meistrar eleven dei fleste rettskrivingsreglane for bokmål eller nynorsk? Har eleven få eller mange skrivefeil?\n- Skriv eleven samansette ord riktig (for eksempel «drikkeflaske», ikkje «drikke flaske»)?\n- Bøyer eleven ord riktig?\n- Følgjer eleven reglane for «då/når»?",
  "criteria_type_structure_label": "Struktur",
  "criteria_type_structure_text_student": "Skrible minner deg på å dele teksten inn i innleiing, hovuddel og avslutning. I tillegg seier Skrible ifrå dersom avsnitta er for lange. Eit avsnitt på meir enn 150 ord blir rekna som langt.",
  "criteria_type_structure_text_teacher": "Skrible minner eleven på å dele teksten inn i innleiing, hovuddel og avslutning. I tillegg seier Skrible ifrå dersom avsnitta er for lange. Eit avsnitt på meir enn 150 ord blir rekna som langt.\n\nDu kan i tillegg vurdere dette:  \n- Er strukturen tilpassa sjangeren?\n- Har teksten tematisk samanheng?\n- Har teksten passe lange avsnitt?\n- Bruker eleven varierte koplingsmarkørar for å skape samanheng i teksten (for eksempel «fordi, men, dersom, likevel»)?",
  "criteria_word_count_evaluation_over": "Du har skrive for mange ord. Teksten skal innehalde",
  "criteria_word_count_evaluation_remaining": "Skriv {{remainingCount}} ord for å nå målet om",
  "criteria_word_count_evaluation_success": "Du har nådd målet om å skrive",
  "criteria_word_count_student_description": "Du skal tilpasse mengda innhald til det teksten handlar om.",
  "criteria_word_count_student_goals_description": "Du skal skrive {{minCount}}–{{maxCount}} ord.",
  "criteria_word_count_teacher_description": "Eleven skal tilpasse mengden innhald til det teksten handlar om. Når du vel dette kriteriet, må du hugse å fylle inn minimum og maksimum tal på ord.",
  "criteria_word_count_teacher_goals_description": "Eleven skal skrive {{minCount}}–{{maxCount}} ord.",
  "criteria_word_count_title": "Talet på ord",
  "tools": {
    "title": "Verktøy",
    "autocorrect": {
      "title": "Autokorrektur",
      "teacher_description": "Eleven kan bruke autokorrektur i skriveoppdraget. Eleven kan trykke på riktig forslag i stavekontrollen, og ordet blir automatisk endra i teksten.",
      "student_description": "Du kan bruke autokorrektur i dette skriveoppdraget.",
    },
    "writing_frame": {
      "none": "Du kan ikkje bruke ei skriveramme i dette skriveoppdraget.",
      "title": "Skriveramme",
      "teacher_description": "Eleven kan bruke ei skriveramme i skriveoppdraget.",
      "student_description": "Du kan bruke ei skriveramme i dette skriveoppdraget.",
      "teacher_goals_description": "Eleven kan bruke skriveramma «{{writingFrame}}» i dette skriveoppdraget.",
      "student_goals_description": "Du kan bruke skriveramma «{{writingFrame}}» i dette skriveoppdraget.",
    },
  },
  "error_page_404_headline": "Skrible finn ikkje denne sida",
  "error_page_404_body": "Dette er ei side som er flytta eller sletta, eller som du ikkje har tilgang til.",
  "error_page_404_button": "Gå tilbake",
  "not_found_404_headline": "Skrible finn ikkje denne sida",
  "not_found_404_body": "Dette er ei side som er flytta eller sletta, eller som du ikkje har tilgang til.",
  "not_found_404_button": "Gå tilbake",
  "error_page_500_headline_1": "Beklagar!",
  "error_page_500_headline_2": "Skrible mista kontakten",
  "error_page_500_body": "Prøv å laste sida på nytt.",
  "error_page_500_button": "Prøv igjen",
  "error_modal_500_headline": "Er du sikker på at du vil gå ut av sida?",
  "error_modal_500_body": "Du har ulagra endringar på sida. Går du ut av sida no, kan du miste endringane dine.",
  "error_modal_500_primary_btn": "Bli på sida",
  "error_modal_500_secondary_btn": "Gå ut og forkast endringane",
  "field_comment_placeholder": "Skriv ein kommentar",
  "field_deadline_placeholder": "Set ein frist",
  "field_general_review_placeholder": "Gi tilbakemelding",
  "field_group_placeholder": "Vel ein klasse",
  "field_learning_goals_count": {
    "one": "{{count}} kompetansemål",
    "other": "{{count}} kompetansemål"
  },
  "field_learning_goals_placeholder": "Legg til kompetansemål",
  "field_missing": "Du må fylle inn alle dei obligatoriske felta før du kan lagre.",
  "field_optional": "(valfritt)",
  "field_writing_frame_placeholder": "Vel skriveramme",
  "field_writing_frame_selected": "Skriveramme: «{{writingFrame}}»",
  "footer_headline": "Skrible",
  //"footer_body_text": "Eit digitalt skriveverktøy frå",
  "footer_body_text": "Eit digitalt skriveverktøy levert av",
  "footer_link_text": "Personvernerklæring",
  "footer_link_ci": "Informasjonskapslar",
  "footer_link_accessibility_statement": "Tilgjengelegheitserklæring",
  "about_us": {
    "title": "Om oss",
    "description": "Skrible er utvikla og eigd av KF og NTB Arkitekst i partnarskap.",
    "kf_description": "KF er eit firma som jobbar kontinuerleg med å utvikle løysingar som skal gjere kvardagen til innbyggjarane i Noreg enklare.\n\nKF har allereie alle kommunane i Noreg på kundelista. Som ei av dei mest innovative verksemdene i Noreg tar KF teknologi som chatbotar og VR ut i norske kommunar.\n\nNo satsar KF i utdanningsmarknaden og lagar nye læremiddel som skal trene grunnleggjande ferdigheiter. KF har fleire pedagogar blant dei tilsette som sørgjer for at behova til elevane, lærarane og skulane blir tatt hand om i utviklinga.",
    "kf_read_more_button": "Les meir om KF her",
    "ntb_arkitekst_description": "NTB Arkitekst er eit språkkonsulentfirma som gjennom dei siste 20 åra har jobba variert med språk og tekst og har ekspertise på blant anna klarspråk, juridisk språk og digitale og byråkratiske tekstar.\n\nNTB Arkitekst har jobba spesielt mykje med ulike tekstar retta mot elevar og lærarar i grunnskolen og vidaregåande opplæring, og denne erfaringa saman med klarspråksvinklinga har vore viktig i utviklinga av Skrible.\n\nNTB Arkitekst er eit dotterselskap til nyheitsbyrået NTB, og i vidareutviklinga av Skrible bruker vi blant anna utviklarar frå NTB.",
    "ntb_arkitekst_read_more_button": "Les meir om NTB Arkitekst her",
    "alt_text_1": "Dame med briller som smiler.",
    "alt_text_2": "Mann som smiler.",
  },
  "home_page": {
    "title": "Bli betre til å skrive,",
    "title_highlight": "eitt ord om gongen",
    "how_it_works": {
      "title_1": "Slik",
      "title_2": "fungerer",
      "title_3": "Skrible",
      "step_1_title": "Lag eit skriveoppdrag",
      "step_1_description": "Når du bruker Skrible, kan du lage eit skriveoppdrag i alle fag og plukke ferdige malar frå oppdragsbanken i Skrible.\n\nVel kva vurderingskriterium som gjeld for skriveoppdraget, og bestem kva kompetansemål elevane skal jobbe med. Skrible gjer det enkelt å tilpasse vurderinga til kvar elev.",
      "step_1_alt_text": "PC som viser Skrible-sida for å lage eit nytt skriveoppdrag.",
      "step_2_title": "Gi elevane skrivero og rettleiing",
      "step_2_description": "Skrible analyserer teksten til elevane medan dei skriv. Elevane vel sjølve når dei vil bruke vurderinga og rettleiinga frå Skrible, og må alltid endre teksten sin sjølve.\n\nTreng eleven litt ekstra skrivero, er det berre å slå på fokusmodus.",
      "step_2_alt_text": "PC som viser Skrible-sida med statusoversikt over skriveoppdrag.",
      "step_3_title": "Spar tid og gi djupare tilbakemeldingar",
      "step_3_description": "Heile skriveprosessen skjer i Skrible – frå du deler ut oppdraget, til du vurderer teksten. Det sparer tid og frustrasjon.\n\nNår elevane er ferdige med teksten sin, har dei allereie vurdert oppbygginga, rettskrivinga, språket og teiknsetjinga si. Det gir deg meir tid til å sjå på heilskapen og gå djupare inn i både teksten og tilbakemeldingane",
      "step_3_alt_text": "PC som viser Skrible-sida til eleven for å redigere skriveoppdrag og tilbakemelding frå læraren.",
      "increase_image_size": "Trykk for å auke størrelsen på bildet",
      "reduce_image_size": "Trykk for å redusere størrelsen på bildet"
    },
    "what_skrible_helps_you_with": {
      "title_1": "Kva",
      "title_2": "hjelper",
      "title_3": "Skrible med?",
      "description": "Skrible er eit skriveverktøy som både er pedagogisk og får deg til å spare tid.",
      "alt_text": "Dame med rosa genser som sit med beina i kors.",
      "step_1_title": "Betre skriveoppleving i alle fag",
      "step_1_description": "Skrible er eit skriveverktøy som eleven og læraren bruker direkte i nettlesaren. Der jobbar dei saman med teknologien for å gjere skriveprosessen meir lærerik og effektiv i alle fag, og læraren kan fokusere på den individuelle tilbakemeldinga til kvar elev.",
      "step_2_title": "Elevane lærer meir med eigenvurdering",
      "step_2_description": "Skrible er eit smartare skriveverktøy. Det rettleier eleven til å gjere teksten betre, og eleven må heile tida ta aktive val.",
      "step_3_title": "Elevane får verdifull støtte",
      "step_3_description": "Vi kombinerer pedagogikk, språkteknologi og klarspråk. Slik får eleven verdifull støtte og opplæring i å skrive – ikkje berre riktig, men også godt.",
      "step_4_title": "Skrible er like god på bokmål og nynorsk",
      "step_4_description": "Skrible er utvikla på bokmål og nynorsk parallelt og fungerer like godt på begge målformer.",
    },
  },
  "info_about_skrible": {
    "title_1": "Kvifor",
    "title_2": "velje Skrible i undervisninga?",
    "description": "Skrible er eit digitalt skriveverktøy for lærarar og elevar frå 5. trinn og oppover som kombinerer språkteknologi og pedagogikk. Med Skrible får elevar og lærarar ein enklare, meir lærerik og smartare skriveprosess.",
    "alt_text": "Gut med briller som rekk opp handa.",
    "for_teachers": {
      "title_1": "For deg som skal",
      "title_2": "undervise",
      "step_1_title": "Ei ny og lærerik oppleving for elevane dine",
      "step_1_description": "Skrible gir elevane vennlege tips undervegs. Tilbakemeldingane gir elevane meir å jobbe med enn berre rettskriving, og Skrible kan sjekke alt frå ordstilling til setningsoppbygging og inndeling i avsnitt.\n\nSkrible minner elevane på utfordringane i teksten, men Skrible rettar ikkje noko automatisk – elevane må alltid gjere endringane sjølve. På den måten er elevane aktive gjennom heile skriveprosessen, dei vurderer eige arbeid undervegs og får ei meir lærerik skriveoppleving.",
      "step_2_title": "Smartare skriveprosess frigjer tid for læraren",
      "step_2_description": "Skrible hjelper deg, men du er framleis sjef og bestemmer kva elevane skal øve på når dei skriv. Heile klassen jobbar med den same oppgåva, men kvar enkelt elev kan ha unikt skrivefokus.\n\nDu kan følgje med og gi tilbakemeldingar undervegs, men sidan Skrible hjelper eleven, er mykje av vurderingsjobben unnagjort når du får teksten. No kan du spare tid og fokusere på det viktigaste i klasserommet, nemleg å følgje opp kvar enkelt elev.",
      "step_3_title": "Ta i bruk Skrible på 1, 2, 3",
      "step_3_description": "Du treng ikkje timevis med opplæring for å ta i bruk Skrible. Alle, både elevar og lærarar, tar verktøyet enkelt i bruk, og du har god tilgang på støtte og rettleiing frå oss i Skrible. Vi er her viss du treng oss, anten på hjelpesidene våre, på YouTube-kanalen vår eller på e-post.",
    },
    "for_administrators": {
      "title_1": "For deg som",
      "title_2": "kjøper inn",
      "step_1_title": "Alle fag",
      "step_1_description": "Elevane skal skrive i alle fag, og derfor finn du alle kompetansemåla frå den nye læreplanen i Skrible. Alle måla kan knytast til skrivearbeidet, også mål frå ulike fag.",
      "step_2_title": "Tilpassa opplæring",
      "step_2_description": "Vi har gjort det enkelt for lærarane å tilpasse skriveopplæringa til kvar enkelt elev. På den måten kan de enkelt sørgje for at alle elevane skriv på sitt eige nivå.",
      "step_3_title": "Vurdering for læring",
      "step_3_description": "Vurdering for læring er eit viktig prinsipp i Skrible. Skrible leier elevane gjennom ei kontinuerleg eigenvurdering. Lærarane held følgje med prosessen og kan gi vurdering både undervegs og til slutt.",
      "step_4_title": "Enkelt å kjøpe, enkelt å bruke",
      "step_4_description": "Prosessen frå kjøp til bruk er ukomplisert og trygg. Skrible og skolen eller kommunen blir saman einige om implementeringa, og vi er alltid her for å hjelpe dykk.\n\nAlle som bruker Skrible, kan vere sikre på at personvernet er i trygge hender. Selskapa bak Skrible – KF og NTB Arkitekst – har lang erfaring med å levere solide produkt til nøgde brukarar.",
    },
    "for_parents": {
      "title_1": "For",
      "title_2": "føresette",
      "step_1_title": "Skriv saman med barnet ditt",
      "step_1_description": "La barnet ditt logge inn i Skrible og vise korleis det går med skriveoppdraga.  Barnet er logga inn med elevkontoen, og de kan saman utforske skriveoppdraga lærarane har gitt. Les gjerne opp teksten eller tilbakemeldingane høgt og snakk saman om teksten.",
      "step_2_title": "Vurder tilbakemeldingane Skrible gir",
      "step_2_description": "Skrible leier elevane gjennom ei kontinuerleg eigenvurdering. Du kan gå gjennom tilbakemeldingane Skrible gir medan eleven skriv. Skryt av at barnet ditt jobbar steg for steg, resultata kjem.",
      "step_3_title": "Skrible er tilgjengeleg",
      "step_3_description": "Universell utforming er eit viktig prinsipp for oss, vi jobbar heile tida for at Skrible skal vere tilgjengeleg for alle. ",
      "step_3_url": "Her er tilgjengeleghetserklæringa til Skrible.",
    },
    "what_teachers_say": {
      "title_1": "Kva lærarar",
      "title_2": "seier",
      "quote": "«Med Skrible kan eleven og læraren få betre tid og fokus på det eg trur er mest meiningsfullt og motiverande for begge – innhald og formidling»",
      "quotist": "lærar på ungdomsskulen",
      "alt_text": "Dame med hjarteforma auge som smiler og peiker på lærersitatet.",
    },
  },
  "pricing": {
    "title": "Rettleiande priser",
    "description": "Alle prisane er ekskl. mva., og dei gjeld for 12 månader.",
    "school": "Skule",
    "school_price": "79 kr per elev",
    "school_description": "for lærarar og elevar på éin eller fleire skular",
    "municipality": "Kommune",
    "municipality_price": "75 kr per elev",
    "municipality_description": "for lærarar og elevar i ein kommune",
    "alt_text": "Smilande lærar med bærbar PC.",
    "where_to_buy": "Du kan kjøpe Skrible direkte, gjennom Pendel eller gjennom Skolon",
    "button_pendel": "Gå til Pendel",
    "button_skolon": "Gå til Skolon",
    "comparison_list": {
      "freemium_title": "Freemium",
      "skrible_title": "Skrible",
      "freemium_version": "testversjon",
      "skrible_version": "fullversjon",
      "you_can": "Du kan",
      "included": "Inkludert",
      "not_included": "Ikkje inkludert",
      "point1": "Lage skriveoppdrag elevane kan jobbe med",
      "point2": "Velje vurderingskriterium til eit skriveoppdrag",
      "point3": "Legge til kompetansemål",
      "point4": "Få tilgang til alle dei nyaste vurderingskriteria",
      "point5": "Tilpasse vurderingskriterium til kvar elev",
      "point6": "Legge til skriverammer og setningsstartarar",
      "point7": "Legge til verktøyet autokorrektur",
      "point8": "Redigere skriveoppdraget etter at du har publisert det",
      "point9": "Gi skriveoppdrag til heile klassen",
      "point10": "Logge inn og sjå alle Feide-gruppane dine",
      "point11": "Få oversikt over alle skriveoppdraga dine",
    },
  },
  "try_skrible": {
    "title_1": "Prøv",
    "title_2": "Skrible gratis",
    "description": "Her kan du prøve ut ein del av funksjonane i Skrible heilt gratis. Du kan både teste korleis du lagar eit skriveoppdrag, og dele det med elevane dine. Elevane dine svarer på skriveoppdraget og sender det inn til deg.\n\nSkrible er laga for å fungere på nettbrett og PC.",
    "step_1_title": "Lag eit skriveoppdrag",
    "step_1_description": "Når du bruker Skrible, kan du lage skriveoppdrag i alle fag til alle elevane dine.\n\nVel kva vurderingskriterium som gjeld for skriveoppdraget, og bestem kva kompetansemål elevane skal jobbe med.\n\nI prøveperioden kan du berre teste ut nokre av vurderingskriteria, og du kan ikkje tilpasse vurderingskriteria til kvar elev.",
    "step_2_title": "Del skriveoppdraget",
    "step_2_description": "Du får ei lenke til skriveoppdraget, som du kan dele med elevane dine. Elevane får automatisk eit brukarnamn og svarer på skriveoppdarget. Så sender dei det inn til deg.",
    "step_3_title": "Vurder skriveoppdraget",
    "step_3_description": "Du får oversikt over kva elevar som har levert og kan vurdere skriveoppdraga direkte i Skrible.",
    "alt_text": "Smilande person med briller som holder ein mobiltelefon.",
    "create_assignment": "Lag eit skriveoppdrag",
  },
  "label_all_groups": "Alle klasser",
  "label_assignment_draft": "Utkast",
  "label_assignment_tools": "Verktøy",
  "label_assignments": "Skriveoppdrag",
  "label_assignments_submitted": "{{count}}/{{total}} sendt inn",
  "label_autosave_success": "Alle endringane er lagra.",
  "label_by": "av",
  "label_class": "Vel klasse",
  "label_completed": "Ferdig",
  "label_deadline": "Frist",
  "label_description": "Beskriving",
  "label_edit_text_tooltip": "Endre teksten din sjølv",
  "label_evaluation_criteria": "Vurderingskriterium",
  "label_evaluation_criteria_count": {
    "one": "{{count}} vurderingskriterium",
    "other": "{{count}} vurderingskriterium"
  },
  "label_filter_learning_goals": "Søk i kompetansemål …",
  "label_filter_discipline": "Søk i fag …",
  "label_filter_learning_goals_empty": "Ikkje noko treff. Sjekk om ordet er stava riktig, eller søk på noko anna.",
  "label_filter_learning_goal_no_filter": "Du må velje klasse eller fag før du kan laste inn kompetansemål.",
  "label_filter_learning_goal_no_filter_selected": "Du må velje klasse eller fag for å laste inn fleire kompetansemål.",
  "label_filter_discipline_empty": "Ikkje noko treff.",
  "label_general_review": "Generell tilbakemelding",
  "label_group": "Klasse",
  "label_hide_interface": "Gå inn i fokusmodus",
  "label_hide_writing_frame": "Skjul skriveramma",
  "label_information": "Til læraren",
  "label_hjelp": "Hjelp",
  "label_learning_goals": "Kompetansemål",
  "label_new_assignment": "Nytt skriveoppdrag",
  "label_page_not_found": "Vi finn ikkje denne sida.",
  "label_personalize_criteria_picker": "Tilpass vurderingskriteriet til kvar elev",
  "label_personalize_tools_picker": "Tilpass verktøyet til kvar elev",
  "label_profile": "Profil",
  "label_review": "Vurdering",
  "label_search": "Søk",
  "label_settings": "Innstillingar",
  "label_show_all": "Sjå alle fag",
  "label_show_interface": "Gå ut av fokusmodus",
  "label_show_writing_frame": "Vis skriveramma",
  "label_students": "Elevar",
  "label_submissions": "Innleveringar",
  "label_tab_all": "Alle",
  "label_tab_assignment": "Skriveoppdraget",
  "label_tab_assignment_bank": "Oppdragsbank",
  "label_tab_completed": "Ferdige",
  "label_tab_drafts": "Utkast",
  "label_tab_open": "Opne",
  "label_tab_student_assignment": "Skriveoppdrag",
  "label_tab_student_text": "Teksten din",
  "label_tab_submissions": "Innleveringar",
  "label_tab_writing_frames": "Skriverammemalar",
  "label_teacher": "Lærar",
  "label_teacher_visibility_learning_goals": "Berre lærarar kan sjå kompetansemåla",
  "label_texts": "Skriveoppdrag",
  "label_website_map": "Nettstadskart",
  "label_words": "ord",
  "label_words_count": "{{count}} ord",
  "lang_page_description": "Du kan byte målform når som helst ved å klikke på menyen i det høgre hjørnet.",
  "lang_page_title": "Nå bruker du Skrible på bokmål.",
  "language": {
    "nynorsk": "Nynorsk",
    "bokmaal": "Bokmål",
  },
  "link_bokmaal": "Bokmål",
  "link_contact": "Kontakt oss",
  "link_help": "Hjelp",
  "link_nynorsk": "Nynorsk",
  "link_pricing": "Prisar",
  "link_signup_consent": "brukarvilkåra for Skrible",
  "link_what_is_skrible": "Kvifor Skrible?",
  "link_about_us": "Om oss",
  "modal_assignment_completed_student_text": "Bra jobba! Læraren din har vurdert teksten. Skriveoppdraget er fullført!",
  "modal_assignment_completed_teacher_text": "Bra jobba! Du har no vurdert teksten. Skriveoppdraget er fullført!",
  "modal_button_submit_for_review": "Send inn",
  "modal_create_draft_text": "Du kan lagre eit utkast til skriveoppdraget og finne det igjen i lista med utkast.",
  "modal_create_draft_title": "Vil du lagre skriveoppdraget som eit utkast?",
  "modal_learning_goals_title": "{{count}} kompetansemål",
  "modal_no_learning_goals_title": "Vel kompetansemål for skriveoppdraget",
  "modal_personalized_criteria_subtitle": "Tilpass vurderingskriteriet til kvar elev",
  "modal_personalized_tools_subtitle": "Tilpass verktøyet til kvar elev",
  "modal_publish_assignment_text": "Når du har lagt ut skriveoppdraget, kan elevane begynne å skrive.",
  "modal_publish_assignment_title": "Vil du leggje ut skriveoppdraget?",
  "modal_submit_review_text": "Når du har sendt vurderinga, kan {{studentName}} sjå over og endre teksten sin.",
  "modal_submit_review_title": "Gi vurderinga di",
  "modal_text_completed_text": "Bra jobba, skriveoppdraget er fullført!",
  "modal_text_completed_title": "Ferdig",
  "modal_text_in_evaluation_text": "No vurderer {{name}} teksten sin.",
  "modal_text_in_evaluation_title": "Blir vurdert av {{name}}",
  "modal_text_in_review_after_deadline_text": "Teksten din blir no vurdert av læraren din {{teacherName}}.",
  "modal_text_in_review_text": "Teksten send inn til vurdering. No kan læraren din {{teacherName}} sjå på teksten og vurdere den på nytt.",
  "modal_text_in_review_title": "Blir vurdert av læraren",
  "modal_text_open_text": "Du har ikkje opna teksten enno.",
  "modal_text_submitted_text": "Når fristen har gått ut, kjem læraren din til å vurdere teksten.",
  "modal_text_submitted_title": "Du har levert skriveoppdraget",
  "modal_title_delete_assignment": "Er du sikker på at du vil slette skriveoppdraget?",
  "modal_title_delete_comment": "Er du sikker på at du vil slette kommentaren?",
  "modal_title_delete_general_review": "Er du sikker på at du vil slette tilbakemeldinga?",
  "modal_text_delete_assignment": "Det blir sletta permanent, og du kan ikkje rette det opp igjen.",
  "modal_text_delete_comment": "Kommentaren blir sletta permanent, og du kan ikkje rette opp igjen.",
  "modal_text_delete_general_review": "Tilbakemeldinga blir sletta permanent, og du kan ikkje rette opp igjen.",
  "modal_alt_button_delete": "Slett",
  "modal_primary_button_delete": "Avbryt",
  "modal_update_draft_text": "Du kan lagre ein oppdatert versjon av utkastet, eller oppdatere utkastet og leggje det ut.",
  "modal_update_draft_title": "Du kan lagre ein oppdatert versjon av utkastet, eller oppdatere utkastet og leggje det ut.",
  "navigation_button_separator": "eller",
  "open_texts_not_available": "Du har ikkje nokre opne skriveoppdrag",
  "radio_button_correct": "Riktig",
  "radio_button_wrong": "Feil",
  "state_completed_label": "Ferdig",
  "state_draft_label": "Utkast",
  "state_past_deadline_label": "Fristen er ute",
  "state_in_review_student_label": "Blir vurdert",
  "state_new_review_student_label": "Ny vurdering",
  "state_new_submission_label": "Ny innlevering",
  "state_not_submitted_label": "Ikkje levert enno",
  "state_ready_to_submit_student_label": "Klar til å sende inn?",
  "state_submitted_label": "Sendt inn til vurdering",
  "text_alt_illustration_kontakt": "Dame som kikkar spørjande og rekkjer opp handa.",
  "text_alt_illustration_solid": "Fleire S-bokstavar i blått, turkis, grønt, limegrønt, oransje og rosa dannar ein sirkel. ",
  "text_choose_language": "Vel målform:",
  "text_contact1_content_header": "Har du spørsmål?",
  "text_contact2_content_header": "Treng du hjelp?",
  "text_contact1_content_text": "Vi svarer gjerne på spørsmål om prøveperiode, kjøp, tilgang på skolen din eller noko anna du lurer på.\n\nVi presenterer gjerne Skrible i eit videomøte, berre ta kontakt!",
  "text_contact2_content_text": "Vi hjelper deg med korleis du loggar på, lagar oppgåver, vurderer tekstar og mykje anna i Skrible.",
  "text_contact_primary_heading_1": "Kontakt",
  "text_contact_primary_heading_2": "oss",
  "text_contact_secondary_heading": "Send oss ein e-post, så svarer vi deg så fort vi kan.",
  "text_content_placeholder": "Skriv teksten din her.",
  "text_error_signup_form_consent": "Du må akseptere brukarvilkåra for å halde fram",
  "text_error_signup_form_email": "Du må fylle ut e-postadresse",
  "text_error_signup_form_municipality": "Du må fylle ut kommune",
  "text_error_signup_form_name": "Du må fylle ut fornamn og etternamn",
  "text_error_signup_form_school": "Du må fylle ut skole",
  "text_feedback_modal_bottom": "Takk for tilbakemeldinga! \nVi bruker tilbakemeldingane til å gjere språkmotoren i Skrible betre. Vi lagrar heile setninga du gir tilbakemelding på.",
  "text_feedback_modal_heading": "Tilbakemelding på forslag",
  "text_feedback_modal_radio_app": "Forslaget er:",
  "text_feedback_modal_radio_user": "Teksten min er:",
  "text_feedback_modal_success": "Takk for tilbakemeldinga!",
  "text_feedback_modal_textfield_head": "Har du andre tilbakemeldingar?",
  "text_feedback_modal_textfield_sub": "Dette er heilt valfritt.",
  "text_in_review_title": "Blir vurdert",
  "text_signup_confirmation_content": "Du får ei stadfesting på e-post når brukaren din i Skrible er aktivert. Det kan ta opp til éin virkedag.",
  "text_signup_confirmation_main_heading": "Takk for at du vil prøve Skrible!",
  "text_signup_confirmation_secondary_heading": "Snart ligg Skrible i innboksen din.",
  "text_signup_consent": "Eg samtykkjer til",
  "text_signup_content_part1": "Vi jobbar enno med å utvikle Skrible. Ved å prøve skriveverktøyet no kan du vere med og påverke kva vi skal forbetre framover. ",
  "text_signup_content_part2": "Lurer du på om skolen din allereie har tilgang til Skrible? Spør oss, så sjekkar vi for deg!",
  "text_signup_content_part3": "Du må ha ein Feide-brukar for å prøve Skrible.",
  "text_signup_form_bottom": "Det er inga bindingstid. Prøv betaversjonen av Skrible gratis fram til 1. oktober.",
  "text_signup_form_button": "Det er inga bindingstid. Prøv betaversjonen av Skrible gratis fram til 1. oktober.",
  "text_signup_form_email": "E-post",
  "text_signup_form_municipality": "Kommune",
  "text_signup_form_name": "Fornamn og etternamn",
  "text_signup_form_school": "Skole",
  "text_signup_main_heading": "Bli kjend med Skrible!",
  "text_signup_secondary_heading": "Prøv Skrible gratis med elevane dine fram til 1. oktober 2020.",
  "text_title_placeholder_student": "Overskrifta på teksten",
  "text_title_placeholder_teacher": "Overskrifta på skriveoppdraget",
  "text_user_guide_body": "Hvis du ikke finner svar på det du lurer på, er det berre å sende oss en e-post. Vi hjelper deg videre!",
  "text_user_guide_primary_heading": "Hjelp og rettleiing",
  "text_user_guide_secondary_heading": "Kva lurar du på?",
  "texts_not_available_student": "Du har ikkje noko skriveoppdrag",
  "texts_not_available_teacher": "Eleven har ikkje noko skriveoppdrag",
  "toolbar_bold": "Feit",
  "toolbar_heading_one": "Overskrift 1",
  "toolbar_heading_two": "Overskrift 2",
  "toolbar_italic": "Kursiv",
  "toolbar_link": "Lenkje",
  "toolbar_link_placeholder": "Lim inn lenkja:",
  "toolbar_list_ordered": "Nummerert liste",
  "toolbar_list_unordered": "Punktliste",
  "toolbar_strikethrough": "Gjennomstreking",
  "toolbar_underline": "Understreking",
  "assignment_filter":{
    "last_month": "Dei siste 30 dagane",
    "last_quart": "Dei siste 90 dagane",
    "last_half": "Det siste halvåret",
    "last_year": "Det siste året",
    "all": "Alle"
  },
  "writing_frame": {
    "about": "Om skriveramma «{{writingFrame}}»",
    "button": {
      "add_module": "Legg til ny",
      "copy": "Kopier og rediger skriveramma",
      "delete": "Slett skriveramma",
      "edit": "Rediger skriveramma",
      "new": "Ny skriveramme",
      "save": "Lagre skriveramma",
      "use": "Bruk skriveramma",
    },
    "created_by": "Laga av ",
    "skrible": "Skrible",
    "you": "Deg",
    "edit_writing_frame": "Rediger skriveramma",
    "label": {
      "description": "Infotekst om sjangeren",
      "language": "Målform",
      "module_size": "Storleik på modulen",
      "sentence_starters": "Setningsstartarar",
      "sentence_starters_tips": "Trykk Enter for å legge til ein skrivestartar.",
      "subject": "Fag",
      "tips": "Tips",
      "title": "Tittel",
    },
    "filter": {
      "all_languages": "Begge målformer",
      "all_subjects": "Alle fag",
      "all_owners": "Laga av alle",
    },
    "modal_delete_writing_frame_title": "Er du sikker på at du vil slette skriveramma?",
    "modal_delete_writing_frame_text": "Ho blir sletta permanent, og du kan ikkje gjenopprette ho.",
    "modules": "Modular",
    "modules_tips": "Du kan endre rekkefølga på modulane ved å dra i ikona til venstre.",
    "modules_tips_2": "Trykk på mellomromstasten for å dra og sleppe modulen, og bruk piltastane for å endre posisjonen til modulen.",
    "modules_edit": "Rediger modulen",
    "no_writing_frame": "Inga skriveramme",
    "not_available": "Det finst ingen slike skriverammer.",
    "placeholder": {
      "language": "Målform",
      "module_tips": "Skriv tips til eleven om modulen.",
      "module_title": "Skriv inn ein tittel på modulen",
      "subject": "Fag",
    },
    "preview": "Førehandsvising",
    "preview_banner": "Førehandsvisning – Slik ser eleven skriveramma «{{writingFrame}}»",
    "size": {
      "small": "Liten",
      "medium": "Middels",
      "large": "Stor",
    },
    "search": "Søk i skriverammer ...",
    "tips_empty": "Her er det ingen tips.",
    // "tips_header": "Tips til «{{module}}»",
    "tips_sentence_starters": "Setningsstartarar",
    "writing_frame": "Skriverammer",
  },
  /**
  * ASSIGNMENT EDITOR TEACHER
  */
  "criteria_word_count_minmax_error": "Du må fylle inn minimum og maksimum tal på ord.",
  "criteria_word_count_min_error": "Minimum tal på ord må vere større enn 0.",
  "criteria_word_count_max_error": "Maksimum tal på ord må vere større enn minimum.",
  "criteria_terms_error": "Du må leggje til minst eitt ord.",
  "tool_writing_frame_error": "Du har ikkje valt skriveramme.",
  "tooltip_remove_word": "Klikk for å fjerne ordet",
  /**
  * BUTTONS
  */
  "button_assignment_bank_nb": "Vel bokmål",
  "button_assignment_bank_nn": "Vel nynorsk",
  "button_back": "Gå tilbake",
  "button_burger_menu": "MENY",
  "button_cancel": "Avbryt",
  "button_cancel_changes": "Forkast endringane",
  "button_comment": "Kommenter",
  "button_confirm": "Legg til",
  "button_contact1_send_email": "Kontakt sal",
  "button_contact2_send_email": "Kontakt support",
  "button_create_draft": "Lagre som utkast",
  "button_delete": "Slett",
  "button_discard_draft": "Slett utkastet",
  "button_done": "Ferdig",
  "button_edit": "Rediger",
  "button_edit_draft": "Rediger utkastet",
  "button_export_pdf_only_text": "Lag ein PDF med berre teksten",
  "button_export_pdf_with_evaluation": "Lag ein PDF som har med vurderinga til Skrible",
  "button_export_pdf_with_evaluation_and_review": "Lag ein PDF som har med både vurderinga til Skrible og vurderinga til læraren",
  "button_export_pdf_with_review": "Lag ein PDF som har med vurderinga til læraren",
  "button_hide_evaluation": "Lukk",
  "button_lang_continue_nb": "Hald fram på bokmål",
  "button_lang_switch_nb": "Byt til bokmål",
  "button_lang_switch_nn": "Byt til nynorsk",
  "button_lang_switch_img_alt_text": "Det norske flagget",
  "button_loading": "Sender inn…",
  "button_loading_execute": "Utførar…",
  "button_loading_create": "Opprettar…",
  "button_loading_delete": "Slettar…",
  "button_loading_evaluation": "Sjekkar…",
  "button_loading_saving": "Lagrar…",
  "button_log_out": "Logg ut",
  "button_login_with_feide": "Logg inn med Feide",
  "button_execute_feide_sync": "Oppdater frå Feide",
  "button_new_assignment": "Nytt skriveoppdrag",
  "button_open_bank": "Utforsk oppdragsbanken",
  "button_open_text": "Opne teksten",
  "button_post": "Kommenter",
  "button_print": "Skriv ut",
  "button_publish": "Legg ut",
  "button_reopen_for_review": "Opne igjen for vurdering",
  "button_restart_all": "Nullstill",
  "button_set_completed": "Marker som ferdig",
  "button_show_evaluation": "Sjekk teksten",
  "button_signup_form_submit": "Kom i gang",
  "button_start_writing": "Begynn å skrive",
  "button_submit_review": "Lever vurdering",
  "button_subscribe_newsletter": "Få nyheitsbrevet vårt",
  "button_try_for_free": "Prøv gratis",
  "button_update": "Oppdater",
  "button_update_and_publish_draft": "Oppdater og legg ut skriveoppdraget",
  "button_update_draft": "Oppdater utkastet",
  "button_user_feedback_cancel": "Lukk",
  "button_user_feedback_submit": "Send inn",
  "button_send_to_student": "Send til eleven",
    /**
   * DROPDOWN OPTIONS
   */
  "drop_down_option_cancel": "Avbryt",
  "drop_down_option_delete": "Slett",
  "drop_down_option_edit": "Rediger",
  "drop_down_option_mark_complete": "Marker som ferdig",
  "drop_down_option_publish": "Legg ut",
  "drop_down_option_reopen": "Opne på nytt",
  "drop_down_option_send": "Send til eleven",
  "drop_down_option_open_assignment": "Opne oppdraget",
  /**
  * MODAL REOPEN SUBMISSION
  */
  "modal_reopen_submission_title":"Opne teksten på nytt",
  "modal_reopen_submission_content": "Eleven kan jobbe vidare med teksten sin. Du kan ikkje vurdere teksten før eleven har levert på nytt.",
  /**
  * MODAL COMPLETE SUBMISSION
  */
  "modal_complete_submission_title": "Vil du markere teksten som ferdig?",
  "modal_complete_submission_content": "Når du har markert teksten som ferdig, kan ikkje eleven jobbe vidare med han.",
  /**
  * MODAL REVIEW PREVIEW
  */
  "modal_review_preview_title": "Vil du vurdere teksten no?",
  "modal_review_preview_content": "Når du vurderer teksten, kan ikkje eleven jobbe vidare med han før du har sendt vurderinga.",
  /**
  * MODAL UPDATE ASSIGNMENT
  */
  "modal_edit_assignment_text1": "Når du har oppdatert skriveoppdraget, kan elevane jobbe vidare med det.",
  "modal_edit_assignment_text2" : "Dei jobbar med den same teksten sin.",
  "modal_edit_assignment_title": "Vil du oppdatere skriveoppdraget?",
  /**
  * MODAL MARK ASSIGNMENT COMPLETE
  */
  "modal_assignment_open_available_text": "Når du har markert skriveoppdraget som ferdig, kan ikkje elevane jobbe vidare med teksten sin.",
  "modal_assignment_open_not_available_text": "Du kan ikkje vurdere skriveoppdraget før fristen har gått ut.",
  "modal_assignment_open_title": "Vil du markere skriveoppdraget som ferdig?",
  /**
  * MODAL RE-OPEN ASSIGNMENT
  */
  "modal_reopen_assignment_title": "Vil du opne skriveoppdraget på nytt?",
  "modal_reopen_assignment_subtitle": "Set ny frist",
  "modal_reopen_assignment_text_1": "Når du har lagt ut skriveoppdraget, kan elevane jobbe vidare med teksten dei har begynt på.",
  "modal_reopen_assignment_text_2": "Du må sette ein ny frist for skriveoppdraget. Vel dato og tid i kalenderen.",
  /**
  * MODAL PUBLISH DRAFT
  */
  "modal_publish_draft_title_missing": "Berre litt igjen ...",
  "modal_publish_draft_text_missing": "Du må gi skriveoppdraget ein tittel og ei beskriving, velje klasse og sette ein frist før du kan leggje det ut. Har du kryssa av for skriveramme, må du gå inn og velje ei skriveramme.",
  "modal_publish_draft_text": "Når du har lagt ut skriveoppdraget, kan elevane jobbe med det fram til fristen.",
  "modal_publish_draft_title": "Vil du leggje ut skriveoppdraget?",
  /**
  * MODAL MISSING FIELDS
  */
   "modal_missing_fields_title": "Bare litt igjen ...",
   "modal_missing_fields_text": "Du må gi skriveramma ein tittel og infotekst og velje fag og målform før du kan lagre.",
  /**
  * STATUS PAGES GLOBAL
  */
  "button_list_drop_down": "Val",
  /**
  * TEACHER ASSIGNMENTS (OVERVIEW)
  */
  "tooltip_assignment_complete": "Ferdig!",
  "tooltip_assignment_draft": "Utkast",
  "tooltip_assignment_in_progress": "Undervegs",
  /**
  * TEACHER SUBMISSIONS (OVERVIEW)
  */
  "tooltip_submission_open": "Ikkje påbegynt",
  "tooltip_submission_writing": "Eleven jobbar med teksten sin",
  "tooltip_submission_submitted": "Klar for tilbakemelding",
  "tooltip_submission_reviewed": "Eleven jobbar med teksten sin",
  "tooltip_submission_complete": "Ferdig!",
   /**
  * STUDENT TEXTS (OVERVIEW)
  */
  "title_text_open": "Du har fått eit nytt skriveoppdrag!",
  //for "title_text_writing" we use the dueDate helper function
  "title_text_submitted": "Læraren din ser på teksten",
  "title_text_reviewed": "Du har fått tilbakemelding",
  "title_text_completed": "Bra jobba. Du er ferdig!",
  "tooltip_text_open": "Nytt skriveoppdrag",
  "tooltip_text_writing": "Du skriv på oppdraget",
  "tooltip_text_submitted": "Læraren vurderer teksten",
  "tooltip_text_reviewed": "Du skriv på oppdraget",
  "tooltip_text_completed": "Ferdig!",
  /**
  * TEACHER REVIEW
  */
  "assignment_preview_notification": "Eleven har levert før fristen. Du kan vurdere teksten med éin gong.",
  "assignment_review_notification": "Eleven jobbar med tilbakemeldinga si. Du kan når som helst vurdere teksten på nytt eller markere han som ferdig.",
  /**
  * STUDENT ASSIGNMENT (TEXT EDITOR)
  */
  "student_preview_header_button": "Skriv vidare",
  "student_submitted_header_button": "Læraren vurderer teksten",
  "student_preview_editor_banner": "Du har sendt inn teksten til vurdering før fristen har gått ut. Du kan når som helst skrive vidare før fristen går ut. ",//leave trailing space
  "student_submitted_editor_banner": "Læraren din vurderer teksten. Du kan jobbe vidare med teksten når læraren har sendt deg tilbakemeldinga si.",
  /**
  * MODAL SUBMIT TEXT AFTER DEADLINE STUDENT
  */
  "modal_submit_text_text": "Etter at du har levert, kan du ikkje gjere endringar i teksten. Etter fristen blir teksten vurdert av læraren din {{teacherName}}.",
  "modal_submit_text_title": "Send inn til vurdering",
  /**
  * MODAL SUBMIT TEXT BEFORE DEADLINE STUDENT
  */
  "modal_submit_preview_text": "Etter at du har levert, kan du opne teksten på nytt og jobbe vidare fram til fristen har gått ut. Etter fristen blir teksten vurdert av læraren din {{teacherName}}.",
  "modal_submit_preview_title": "Send inn til vurdering",
  /**
  * MODAL OPEN TEXT PREVIEW - Elev har levert før fristen og åpner teksten for å skrive videre før fristen går ut
  */
  "modal_open_preview_text": "Når du opnar teksten, kan du jobbe vidare med han. Du må levere på nytt før fristen går ut.",
  "modal_open_preview_title": "Opne teksten på nytt",
  /**
  * MODAL PICK GREP GOALS
  */
  "learning_goal_discipline_title": "Fag",
  "learning_goal_grade_title": "Årstrinn",
  /**
  * STUDENT SEGMENTED ANALYZE
  */
  "analyze_loading_category_communication": "Sjekkar kommunikasjon" ,
  "analyze_loading_category_content": "Sjekkar innhaldet" ,
  "analyze_loading_category_spelling": "Sjekkar skrivereglane" ,
  "analyze_loading_category_language": "Sjekkar språkbruken" ,
  "analyze_loading_category_structure": "Sjekkar strukturen" ,
  "analyze_loading_category_punctuation": "Sjekkar teiknsetjinga"
};
