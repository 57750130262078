/**
 * Script: helpers/lokalise.ts
 * This file contains helper functions related to localization.
 *
 * Dependencies are
 * i18n              - i18n-js      - Localization library
 * nb                - locale/nb    - Translations for Norwegian Bokmål
 * nn                - locale/nn    - Translations for Norwegian Nynorsk
 */

import i18n from "i18n-js";
// import memoize from 'lodash.memoize';

import nb from "../locale/nb";
import nn from "../locale/nn";

i18n.defaultLocale = "nb";

i18n.missingTranslation = scope => `{${scope}}`;

i18n.translations = {
  nb,
  nn
};

i18n.locale = "nb";

// const translate = memoize(
//   (key, config) => i18n.t(key, config),
//   (key, config) => (config ? key + JSON.stringify(config) : key)
// );

/**
 * helper translate function, which will translate only strings
 * starting with 'key.'. Otherwise return original.
 *
 * @param {string} key - string to translate
 * @param {object} options - other parameters for I18n.t()
 *
 * @return translated string if starts with 'key.'
 *         same string otherwise
 */
export function t(key: string, options = {}): string {
  const translation = i18n.t(key, options);

  if (translation == "") return `{${key}}`;
  return translation;
}

/**
 * change locale for i18n translations
 * @param {Language} language
 */
export function setLocale(language: string): void {
  i18n.locale = language;
}

/**
 * Get the selected locale's langiage code.
 */
export function getLocale(): string {
  return i18n.locale;
}

// export const locale = i18n.locale;
