/**
 * Script: constants/styled.tsx
 * This file contains global styling and theming.
 *
 * Dependencies are
 *
 * css, styled            - @emotion/               - Emotion styling components
 * normalize              - emotion-normalize       - provides cross-browser consistency
 * CustomTheme            - types                   - Interface
 */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import normalize from "emotion-normalize";

import { CustomTheme } from "types";

/**
 * Theming.
 */
export const theme: CustomTheme = {
  name: "Light", // Name is required for storybook theme plugin
  colors: {
    // Interface
    accent: "#4754ff",
    border: "#e8e8e8",
    borderDark: "#d2d2d2",
    comment: "#f6ca59",
    selectionBg: "#bdbefa",
    selectionText: "#11113b",
    // Typography
    off: "#767676",
    primary: "#212121",
    secondary: "#666666",
    // Signal
    error: "#FE1212",
    success: "#078832",
    warning: "#A86500",
    // Evaluation
    communication: "#5263FE",
    content: "#027EB3",
    language: "#7E790D", // '#628006',
    punctuation: "#B637E3",
    spelling: "#038850", // '#048A09',
    structure: "#C6501D",
    tools: "#4754FF",
    writingFrame: "#7E790D", // '#628006',
    //custom landing page:
    headerLinks: "#030303",
    palePurple: "#e9e9fd"
  },
  focus: {
    shadow: "0px 0px 0px 5px rgba(71, 84, 255, 0.3)",
    outlineColor: "rgba(71, 84, 255, 0.3)",
    borderColor: "#4754ff",
    borderRadius: "6px"
  },
  warning: {
    shadow: "0px 0px 0px 5px rgba(214, 144, 0, 0.3);",
    borderColor: "#D69A00"
  },
  error: {
    shadow: "0px 0px 0px 5px rgb(254,18,18, 0.3)"
  },
  size: {
    header: "3.5rem", // 56px
    contentTop: "8.5rem", // 136px = 80px + 56px (header)
    //custom landing page
    homeFooter: "305px",
    homeFooterMobile: "445px",
    homeHeader: "120px"
  },
  zIndex: {
    header: 4,
    tabHeader: 3,
    hoverMenu: 100,
    modal: 6,
    overlay: 100
  }
};

/**
 * Global styling.
 */
export const globalStyles = css`
  ${normalize};

  @font-face {
    font-family: "Atlas Grotesk";
    src: local("Atlas Grotesk"), url("/fonts/subset-AtlasGrotesk-Medium.woff2") format("woff2"),
      url("/fonts/subset-AtlasGrotesk-Medium.woff") format("woff"),
      url("/fonts/subset-AtlasGrotesk-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Atlas Grotesk";
    src: local("Atlas Grotesk"), url("/fonts/subset-AtlasGrotesk-MediumItalic.woff2") format("woff2"),
      url("/fonts/subset-AtlasGrotesk-MediumItalic.woff") format("woff"),
      url("/fonts/subset-AtlasGrotesk-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Atlas Grotesk";
    src: local("Atlas Grotesk"), url("/fonts/subset-AtlasGrotesk-RegularItalic.woff2") format("woff2"),
      url("/fonts/subset-AtlasGrotesk-RegularItalic.woff") format("woff"),
      url("/fonts/subset-AtlasGrotesk-RegularItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Atlas Grotesk";
    src: local("Atlas Grotesk"), url("/fonts/subset-AtlasGrotesk-BoldItalic.woff2") format("woff2"),
      url("/fonts/subset-AtlasGrotesk-BoldItalic.woff") format("woff"),
      url("/fonts/subset-AtlasGrotesk-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Atlas Grotesk";
    src: local("Atlas Grotesk"), url("/fonts/subset-AtlasGrotesk-Bold.woff2") format("woff2"),
      url("/fonts/subset-AtlasGrotesk-Bold.woff") format("woff"),
      url("/fonts/subset-AtlasGrotesk-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Atlas Grotesk";
    src: local("Atlas Grotesk"), url("/fonts/subset-AtlasGrotesk-Regular.woff2") format("woff2"),
      url("/fonts/subset-AtlasGrotesk-Regular.woff") format("woff"),
      url("/fonts/subset-AtlasGrotesk-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Beatrice";
    src: local("Beatrice"), local("Beatrice Semibold"), local("Beatrice-Semibold"),
      url("/fonts/Beatrice-Semibold.woff2") format("woff2"), url("/fonts/Beatrice-Semibold.woff") format("woff"),
      url("/fonts/Beatrice-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: "Atlas Grotesk", Arial, sans-serif;
    color: ${theme.colors.primary};

    overflow-x: hidden;
  }

  ::selection {
    background-color: ${theme.colors.selectionBg};
    color: ${theme.colors.selectionText};
  }

  /* Utility styles */
  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  /* Reset styles */

  b,
  strong {
    font-weight: 500;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* Reset default focus styles on mouse events */
  :focus {
    outline: none;
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /* Global print styles */
  @media print {
    * {
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }
  }

  /* Editor styles, here for now */

  [data-slate-editor] {
    p {
      font-size: 18px;
      line-height: 1.7;
      margin-bottom: 20px;
    }

    ol,
    ul {
      margin: 1em 0;
      padding-left: 40px;
    }

    ol {
      list-style: decimal;

      li {
        font-size: 1.125rem; /* 18px */
        line-height: 1.7;

        margin-bottom: 2rem; /* 32px */
        padding-left: 0.5rem; /* 8px */
      }
    }

    ul {
      list-style: disc;

      li {
        font-size: 1.125rem; /* 18px */
        line-height: 1.7;

        margin-bottom: 2rem; /* 32px */
        padding-left: 0.5rem; /* 8px */
      }
    }
  }

  [data-mark-type] {
    border-bottom: 2px solid transparent;
    padding: 3px 0;

    cursor: pointer;
    transition: border-color 200ms ease, background 200ms ease;
  }

  [data-mark-type="capital_letters"],
  [data-mark-type="space_count"],
  [data-mark-type="possessives"],
  [data-mark-type="og_aa"],
  [data-mark-type="spellcheck"] {
    border-color: ${theme.colors.spelling};
  }

  [data-mark-type="commas"],
  [data-mark-type="exclamation_marks"],
  [data-mark-type="full_stops"],
  [data-mark-type="question_marks"] {
    border-color: ${theme.colors.punctuation};
  }

  [data-mark-type="long_sentences"],
  [data-mark-type="repetition"],
  [data-mark-type="passive_voice"] {
    border-color: ${theme.colors.language};
  }

  [data-mark-type="paragraphs"] {
    border-color: ${theme.colors.structure};
  }

  [data-mark-type="comment"] {
    border-color: ${theme.colors.comment};
  }

  [data-mark-type].isActive {
    &[data-active-mark-type="capital_letters"],
    &[data-active-mark-type="space_count"],
    &[data-active-mark-type="possessives"],
    &[data-active-mark-type="og_aa"],
    &[data-active-mark-type="spellcheck"] {
      background: #aaf1d4;
      border-color: #aaf1d4;
    }

    &[data-active-mark-type="commas"],
    &[data-active-mark-type="exclamation_marks"],
    &[data-active-mark-type="full_stops"],
    &[data-active-mark-type="question_marks"] {
      background: #eebcfa;
      border-color: #eebcfa;
    }

    &[data-active-mark-type="long_sentences"],
    &[data-active-mark-type="repetition"],
    &[data-active-mark-type="passive_voice"] {
      background: #e2eea6;
      border-color: #e2eea6;
    }

    &[data-active-mark-type="paragraphs"] {
      background: #f5cca6;
      border-color: #f5cca6;
    }

    &[data-active-mark-type="comment"] {
      background: ${theme.colors.comment};
    }
  }
`;

export default styled;
