/**
 * Script: helpers/dates.ts
 * This file contains helper functions related to date, time and deadline.
 * Dependencies are
 * differenceInCalendarDays, format,
   formatDistanceStrict, isAfter, isPast     - date-fns            - Date library
 * nn, nb, enGB                              - date-fns/locale     - Date library
 * t, getLocale                              - helpers/lokalise    - Localization functio
 */
import { differenceInCalendarDays, format, formatDistanceStrict, isAfter, isPast } from "date-fns";
import { nn, nb, enGB } from "date-fns/locale";

import { t, getLocale } from "helpers/lokalise";

const allLocales = { nn, nb, en: enGB };

/**
 * Checks if date has passed.
 * @param {string} deadline Date as a string.
 * @returns {boolean} true/false.
 */
export function checkDeadline(deadline: string): boolean {
  const parsed = new Date(deadline);
  return isPast(parsed);
}

/**
 * Checks if date is valid. The date must be defined and be after year 2000.
 * @param {string} deadline Date as a string.
 * @returns {boolean} true/false.
 */
export function isDeadlineValid(deadline?: string): boolean {
  if (!deadline) {
    return false;
  }
  // HACK: When creating a draft if deadline is not selected
  //       we get Unix timestamp 0 (January 1970) as deadline
  //       we compare it to a date long before Skrible to check its validity
  const parsed = new Date(deadline);
  const dateLongBeforeSkrible = new Date(2000, 0);
  return isAfter(parsed, dateLongBeforeSkrible);
}

/**
 * Formatting a date string to a string on the format "do MMMM yyyy 'kl' k.mm"
 * @param {string} deadline Date as a string.
 * @returns {string} Formatted date.
 */
export function parseDeadline(deadline: string): string {
  const parsed = new Date(deadline);
  const pattern = "do MMMM yyyy 'kl' k.mm";

  return format(parsed, pattern, { locale: allLocales[getLocale()] });
}

/**
 * Gets a date and returns a deadline as a descriptional text with formatted date and time.
 * @param {string} deadline Date as a string.
 * @returns {string} Descriptional text about when the date is due.
 */
export function getDueByDeadline(deadline: string): string {
  const parsed = new Date(deadline);

  const date = format(parsed, "EEEE do MMMM yyyy", { locale: allLocales[getLocale()] });
  const time = format(parsed, "k.mm", { locale: allLocales[getLocale()] });

  return t("assignment_due_date", { date, time });
}

/**
 * Gets a date and returns a deadline as a descriptional text with formatted and compact date and time.
 * @param {string} deadline Date as a string.
 * @returns {string} Descriptional text about when the date is due.
 */
export function getCompactDueByDeadline(deadline: string): string {
  const parsed = new Date(deadline);

  const date = format(parsed, "do MMMM", { locale: allLocales[getLocale()] });
  const time = format(parsed, "k.mm", { locale: allLocales[getLocale()] });

  return t("assignment_due_date", { date, time });
}

/**
 * Gets a date and returns number of days between the date and today.
 * @param {string} deadline Date as a string.
 * @returns {number} Number of days.
 */
export function getDaysToDeadline(deadline: string): number {
  const parsed = new Date(deadline);
  const now = new Date();

  return differenceInCalendarDays(parsed, now);
}

/**
 * Gets a date and returns time between the date and today.
 * @param {string} datestring Date as a string.
 * @param {boolean} addSuffix Should suffix be included?
 * @returns {string} Time passed as a descriptional string.
 */
export function getTimeFromNow(datestring: string, addSuffix = false): string {
  const parsed = new Date(datestring);
  const now = new Date();

  return formatDistanceStrict(parsed, now, { addSuffix, locale: allLocales[getLocale()] });
}

/**
 * Gets a date and returns a string describing whether the date has passed, number of days until due date or if the due date is today.
 * @param {string} deadline Date as a string.
 * @returns {string} Description of the deadline.
 */
export function getDueText(deadline: string): string {
  const daysToDeadline = getDaysToDeadline(deadline);

  if (checkDeadline(deadline)) return t("state_past_deadline_label"); // 'Fristen er ute'
  if (daysToDeadline > 0) return t("assignment_due_days", { count: daysToDeadline });
  return t("assignment_due_today");
}
