/**
 * Script: helpers/services.ts
 * This file contains all of Skrible's services.
 * Dependencies are
 *
 * api                                    - helpers/api   - Functions to make HTTP requests.
 * Assignment, Comment, Review, Text,
   TextAnalyzeData, WritingFrame          - types         - Interface
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from "helpers/api";
import { Assignment, Comment, Review, Text, TextAnalyzeData, WritingFrame } from "types";

const { del, get, getNoHumps, post, put, file } = api;

const baseUrl = "/api";
const externalApiUrl = "/admin/api";

const assignments = {
  create: (assignment: Assignment) => post(`${baseUrl}/assignment`, assignment),
  delete: (assignmentId: string) => del(`${baseUrl}/assignment/${assignmentId}`),
  get: (assignmentId: string) => get(`${baseUrl}/assignment/${assignmentId}`),
  getAll: () => get(`${baseUrl}/assignment`),
  getAllWindow: (window?: string) => get(`${baseUrl}/assignment?window=${window}`),
  setState: (assignmentId: string, state: string) => post(`${baseUrl}/assignment/${assignmentId}/${state}`, {}),
  update: (assignmentId: string, assignment: Assignment) => put(`${baseUrl}/assignment/${assignmentId}`, assignment)
};

const auth = {
  getGroup: (groupId: string) => get(`/api/groups/${groupId}`),
  getGroups: () => get("/api/groups"),
  getMe: () => get("/api/me"),
  getCurrentUser: () => get("/dataporten/me"),
  getUser: (userId: string) => get(`/api/user/${userId}`),
  getUserProfile: (userId: string) => get(`/api/student/${userId}`),
  runFeideSync: () => get("/dataporten/feide_sync")
};

const comments = {
  getAll: (textId: string) => get(`${baseUrl}/text/${textId}/comments`),
  create: (textId: string, comment: Comment) => post(`${baseUrl}/text/${textId}/comments`, comment),
  delete: (textId: string, commentId: string) => del(`${baseUrl}/text/${textId}/comments/${commentId}`),
  update: (textId: string, commentId: string, comment: Comment) =>
    put(`${baseUrl}/text/${textId}/comments/${commentId}`, comment)
};

const criteria = {
  getAll: () => get(`${baseUrl}/criteria`),
  get: (textId: string) => get(`${baseUrl}/criteria/${textId}`)
};

const guide = {
  getAllCategories: () => getNoHumps(`${externalApiUrl}/guide/category`),
  getAllQuestions: () => getNoHumps(`${externalApiUrl}/guide/question`),
  getPageSettings: () => getNoHumps(`${externalApiUrl}/guide/settings`),
  getQuestionsByCategory: (categoryId: string) => getNoHumps(`${externalApiUrl}/guide/${categoryId}`)
};

const language = {
  update: (language: string) => put(`${baseUrl}/language`, { language })
};

const learningGoals = {
  getAll: () => get(`${externalApiUrl}/learning_goal`),
  get: (goalId: string) => get(`${baseUrl}/learning_goal/${goalId}`)
};

const grepLearningGoals = {
  getDisciplinesByGrade: (params: URLSearchParams) => get(`${externalApiUrl}/learning_goal/disciplines?${params}`),
  getGrades: () => get(`${externalApiUrl}/learning_goal/grades`),
  getLearningGoalById: (id: string) => get(`${externalApiUrl}/learning_goal/grep/${id}`),
  getLearningGoals: (params: URLSearchParams) => get(`${externalApiUrl}/learning_goal/grep_short?${params}`),
};

const messageModals = {
  getSelectedStory: (env: string, role: string) => getNoHumps(`${externalApiUrl}/modals/active/${env}/${role}`),
  getStoriesByType: (type: string) => getNoHumps(`${externalApiUrl}/modals/${type}`),
};

const premade = {
  getAll: () => get(`${externalApiUrl}/premade`),
  get: (premadeAssignmentId: string) => get(`${baseUrl}/premade/${premadeAssignmentId}`)
};

const reviews = {
  getAll: (textId: string) => get(`${baseUrl}/text/${textId}/review`),
  create: (textId: string, review: Review) => post(`${baseUrl}/text/${textId}/review`, review),
  delete: (textId: string, reviewId: string) => del(`${baseUrl}/text/${textId}/review/${reviewId}`),
  update: (textId: string, reviewId: string, review: Review) =>
    put(`${baseUrl}/text/${textId}/review/${reviewId}`, review)
};

const search = {
  query: (params: URLSearchParams) => get(`${baseUrl}/search?${params}`),
  learningGoal: (params: URLSearchParams) => get(`${baseUrl}/search/learning_goal?${params}`)
};

const texts = {
  analyze: (data: TextAnalyzeData) => post(`${baseUrl}/analyze`, data),
  create: (text: Text) => post(`${baseUrl}/text`, text),
  get: (textId: string) => get(`${baseUrl}/text/${textId}`),
  getAll: () => get(`${baseUrl}/text`),
  setState: (textId: string, state: string) => post(`${baseUrl}/text/${textId}/${state}`, {}),
  update: (textId: string, text: Text) => put(`${baseUrl}/text/${textId}`, text),
  export: ({
    text,
    withEvaluation = false,
    withReview = false
  }: {
    text: Text;
    withEvaluation?: boolean;
    withReview?: boolean;
  }) => file(`${baseUrl}/export/${text.id}?analyze=${withEvaluation}&comments=${withReview}`, { text: text.content }),
  backup: (textId: string, content: string) => post(`${baseUrl}/text-backup`, { textId, content })
};

const subjects = {
  getAll: () => get(`${baseUrl}/subjects`),
}

const premadeWritingFrames =  {
  getAll: () => get(`${externalApiUrl}/premadeWritingFrame`),
  get: (writingFrameId: string) => get(`${externalApiUrl}/premadeWritingFrame/${writingFrameId}`),
}

const writingFrames = {
  create: (writingFrame: WritingFrame) => post(`${baseUrl}/writing_frame`, writingFrame),
  delete: (writingFrameId: string) => del(`${baseUrl}/writing_frame/${writingFrameId}`),
  getAll: () => get(`${baseUrl}/writing_frame`),
  get: (writingFrameId: string) => get(`${baseUrl}/writing_frame/${writingFrameId}`),
  update: (writingFrameId: string, writingFrame: WritingFrame) => put(`${baseUrl}/writing_frame/${writingFrameId}`, writingFrame),
}

export default {
  assignments,
  auth,
  comments,
  criteria,
  grepLearningGoals,
  guide,
  language,
  learningGoals,
  messageModals,
  premade,
  reviews,
  search,
  texts,
  subjects,
  premadeWritingFrames,
  writingFrames,
};
